import * as React from 'react'
import { Spring } from 'react-spring'
import _ from 'lodash';
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import styled from 'styled-components'
import './style.css'
const StyledContainer = styled.div`
  padding: 0 20px;
  font-size: 12px;
`
const barGraphData = [
  {
    tableID: 1,
    title: "1 Room Apartment in City Centre, Rent Per Month",
    resourceTitle: "numbeo.com", url: "https://www.numbeo.com/cost-of-living/country_price_rankings?itemId=26",
    Yaxis: ["$0", "$1,000", "$2,000", "$3,000", "$4,000", "$5,000"],
    items: [
      {
        itemID: 1,
        Xaxis: "DE",
        value: 18,
        label_comment: "Germany $1017",
      },
      {
        itemID: 2,
        Xaxis: "UK",
        value: 23,
        label_comment: "UK $1366",
      },
      {
        itemID: 3,
        Xaxis: "US",
        value: 37,
        label_comment: "US $1770",
      },
      {
        itemID: 4,
        Xaxis: "CH",
        value: 66,
        label_comment: "Switzerland $2059",
      },
    ]
  },
  {
    tableID: 2,
    title: "Life Expectancy in Japan",
    resourceTitle: "datacommons.org",
    url: "https://datacommons.org/tools/timeline#&place=country/JPN&statsVar=LifeExpectancy_Person",
    Yaxis: [0, 20, 40, 60, 80, 100],
    items: [
      {
        objectID: 1,
        Xaxis: "1960",
        value: 67,
        label_comment: "1960 67years",
      },
      {
        objectID: 2,
        Xaxis: "1980",
        value: 76,
        label_comment: "1980 76years",
      },
      {
        objectID: 3,
        Xaxis: "2000",
        value: 81,
        label_comment: "2000 81years",
      },
      {
        objectID: 4,
        Xaxis: "2019",
        value: 84,
        label_comment: "2019 84years",
      }
    ]
  },
  {
    tableID: 3,
    title: "JPY to US Dollar(¥100 = $?)",
    resourceTitle: "Google Finance",
    url: "https://www.google.com/finance/quote/JPY-USD?window=MAX",
    Yaxis: ["$0", "$0.24", "$0.48", "$0.72", "$0.96", "$1.2"],
    items: [
      // {
      //   itemID: 1,
      //   Xaxis: "2000",
      //   value: 78,
      //   label_comment: "2000 $0.93",
      // },
      {
        itemID: 1,
        Xaxis: "2008",
        value: 93,
        label_comment: "2008 Subprime Loan $1.12",
      },
      {
        itemID: 2,
        Xaxis: "2020",
        value: 76,
        label_comment: "2020 $0.91",
      },
      {
        itemID: 3,
        Xaxis: "2022",
        value: 58,
        label_comment: "2022 Corona $0.7",
      },
      {
        itemID: 4,
        Xaxis: "2024",
        value: 56,
        label_comment: "2024 Tokyo stocks 340yr high $0.67",
      },
    ]
  }
]
const VerticalBar = ({ progress, Xaxis, label_comment }) => {
  return (
    <Spring from={{ percentage: 0 }} to={{ percentage: progress }}>
      {({ percentage }) => (
        <div>
          <div className='progress-hover-label-container'>
            <div className='progress-hover-label'>
              <span className='progress-hover-label-item'>{label_comment}</span>
            </div>
          </div>
          <div className="sr-only">{`${progress}%`}</div>
          <div className="progress vertical">
            <div style={{ maxHeight: `${progress}%` }} className='progress-bar'></div>
          </div>
          <div
            className='progress-label'
          >{Xaxis}
          </div>
        </div>
      )}
    </Spring>
  )
}

function GraphingGenerator() {

  const [graphTitle, setGraphTitle] = React.useState([1]);
  const onChange2Topic = (e) => {
    setGraphTitle(e.target.value);
  }
  const table = barGraphData[Number(`${graphTitle}` - 1)]
  return (
    <StyledContainer>
      <h4 className='sample-title'>Bar Grapgh Generator</h4>
      <div className='sample-contents-container-004'>
        <div className='sample-title-container-004'>
          <div className='tag-container'>
            <span className='tech-tag'>React</span><span className='tech-tag'>react-spring</span>
          </div><br />
        </div>
        <div className='table-container'>
          <div>
            <div className='select-field'>
              <label>Select topic</label><br />
              <select
                onChange={onChange2Topic}
                className="table-title-container"
              >
                {barGraphData.map((x) => (
                  <option value={x.tableID}>{x.title}</option>
                ))}
              </select>
            </div>
            <div>
              Data Resource : {table.resourceTitle}<br />
              <a href={table.url}>▶︎ Click here to see the original data</a>
            </div>
          </div>
          <div>
            <h4>Data Result :</h4>
            <div className='graph-container'>
              <div className='graph-horizontal-lines'>
                <div className="graph-horizontal-line index0"><div className='label'>{table.Yaxis[0]}</div><div className='tick'></div></div>
                <div className="graph-horizontal-line index1"><div className='label'>{table.Yaxis[1]}</div><div className='tick'></div></div>
                <div className="graph-horizontal-line index2"><div className='label'>{table.Yaxis[2]}</div><div className='tick'></div></div>
                <div className="graph-horizontal-line index3"><div className='label'>{table.Yaxis[3]}</div><div className='tick'></div></div>
                <div className="graph-horizontal-line index4"><div className='label'>{table.Yaxis[4]}</div><div className='tick'></div></div>
                <div className="graph-horizontal-line index5"><div className='label'>{table.Yaxis[5]}</div><div className='tick'></div></div>
              </div>
              <div className='graph-bars'>
                {table.items.map((item) => (
                  <VerticalBar
                    defaultValue={0}
                    key={item.itemsID} progress={item.value} Xaxis={item.Xaxis} label_comment={item.label_comment} />
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </StyledContainer>
  )
}
export default GraphingGenerator