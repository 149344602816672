import * as React  from 'react'
import styled from 'styled-components'
import {sortBy} from 'lodash'
import axios from 'axios'
import Checkbox from '@mui/material/Checkbox';
import {BsSortAlphaDown, BsSortNumericDown, BsSortNumericUp} from 'react-icons/bs'
import { AiOutlineSortDescending} from 'react-icons/ai'
import './style.css'

const StyledContainer= styled.div`
  padding: 0 20px;
  font-size: 12px;
`

const StyledInput = styled.input`
  border: none;
  border: 1px solid #888784;
  background-color: transparent;
  font-size: 12px;
`
const StyledSearchForm=styled.form`
  align-items: baseline;
  height: 24px;
  text-align: left;
`

const moviesList = [
  {
    movie_title: 'Abatar',
    released_year: 2009,
    revenue: 2.84
  },
  {
    movie_title: 'Titanic',
    released_year: 1997,
    revenue: 2.18
  },
  {
    movie_title: 'Star Wars: The Force Awakens',
    released_year: 2015,
    revenue: 2.06
  },
  {
    movie_title: 'Spider-Man: No Way Home film currently playing',
    released_year: 2021,
    revenue: 1.90
  },
  {
    movie_title: 'Jurassic World',
    released_year: 2015,
    revenue: 1.67
  },
  {
    movie_title: 'The Lion King',
    released_year: 2019,
    revenue: 1.65
  },
  {
    movie_title: 'Star Wars: Episode I – The Phantom Menace',
    released_year: 1999,
    revenue: 1.02
  }
]
const getUrl = (searchTerm) => `${searchTerm}`
const SearchForm = ({
  searchTerm,
  onSearchInput,
  onSearchSubmit
})=> (
  <StyledSearchForm onSubmit={onSearchSubmit}>
    <InputWithLabel
      id='search'
      value={searchTerm}
      onInputChange={onSearchInput}
    />
  </StyledSearchForm>
)
class InputWithLabel extends React.Component {
  render() {
    const {
      id, 
      value, 
      type = 'text',
      onInputChange,
      isFocused
    } = this.props
  return (
    <>
      <StyledInput
        id={id}
        type={type}
        value={value}
        auto={isFocused}
        onChange={onInputChange}
      />
    </>
  )
}}
const SORTS = {
  NONE:(list)=> list,
  MOVIE_TITLE:(list)=>sortBy(list, 'movie_title'),
  RELEASED_YEAR:(list)=> sortBy(list, 'released_year').reverse(),
  REVENUE: (list)=> sortBy(list, 'revenue').reverse()
}
const List = ({list}) => {
    const [sort, setSort] = React.useState({
      sortKey: 'NONE',
      isReverse: false
    })
    const handleSort = (sortKey)=> {
      const isReverse = sort.sortKey === sortKey && !sort.isReverse
      setSort({sortKey, isReverse})
    }
    const sortFunction = SORTS[sort.sortKey]
    const sortedList = sort.isReverse
      ? sortFunction(list).reverse()
      : sortFunction(list)
    return (
        <ul className="movie_table">
          <li className='ttl-li'>
            <span className='ttl-lg'>
              Movie Title
              <Checkbox
                onClick={()=> handleSort('MOVIE_TITLE')}
                color='secondary'
                icon={<BsSortAlphaDown className="order-btn"/>}
                checkedIcon={<AiOutlineSortDescending/>}
              />
            </span>
            <span className='ttl-sm'>
              Released year
              <Checkbox
                onClick={()=> handleSort('RELEASED_YEAR')}
                color='secondary'
                icon={<BsSortNumericDown className="order-btn"/>}
                checkedIcon={<BsSortNumericUp/>}
              />
            </span>
            <span className='ttl-sm'>
              Revenue
              <Checkbox
                onClick={()=> handleSort('REVENUE')}
                color='secondary'
                icon={<BsSortNumericDown className="order-btn"/>}
                checkedIcon={<BsSortNumericUp/>}
              />
            </span>
          </li>
        {sortedList.map((item)=> (
           <Item 
              key={item.objectID} 
              item={item}
          />
        ))}
        </ul>
    )
}
const Item = ({item}) => {
  return (
      <li className='item-li'>
      <span className='item-lg'>{item.movie_title}</span><br/>
      <span className='item-sm'>{item.released_year}</span><br/>
      <span className='item-sm'>${item.revenue}Billion</span>
      </li>
  )
}
const useSemiPersistentState = (key, initialState) => {
  const [value, setValue] = React.useState(
    localStorage.getItem(key) || initialState
  )
  React.useEffect(() => {
    localStorage.setItem(key, value)
  }, [value, key])
  
  return [value, setValue]
}
const getLastSearches = (urls) => urls.slice(-5)
const moviesReducer = (state, action) => {
  switch(action.type){
    case 'MOVIES_FETCH_INIT':
      return {
        ...state,
        isLoading: true,
        isError: false
      }
    case 'MOVIES_FETCH_SUCCESS':
      return {
        ...state,
        isLoading: false,
        isError: false,
        moviesList: action.payload
      }
    case 'MOVIES_FETCH_FAILURE':
      return {
        ...state,
        isLoading: false,
        isErrror: true
      }
    default:
      throw new Error()
  }
}

export const ReduxWithOriginalData2 = () => {
  const getAsyncMovies = () => 
    new Promise((resolve, reject)=> setTimeout(reject, 2000))
  const [searchTerm, setSearchTerm] = useSemiPersistentState('search', 'star wars')
  const [urls, setUrls] = React.useState([getUrl(searchTerm)])
  const [isLoading, setIsLoading] = React.useState(false)
  const [isError, setIsError] = React.useState(false)
  const [movies, dispatchMovies] = React.useReducer(
    moviesReducer, 
    {moviesList, isLoading: false, isError: false}
  )
  const searchedMovies = moviesList.filter((movie)=>
  movie.movie_title.toLowerCase().includes(searchTerm.toLowerCase())
  )
  const handleFetchMovies = React.useCallback(async()=> {
    dispatchMovies({type: 'MOVIES_FETCH_INIT'})
    try {
      const lastUrl = urls[urls.length -1]
      const result = await axios.get(lastUrl)
      dispatchMovies({type: 'MOVIES_FETCH_SUCCESS', payload: result.moviesList.hits})
    } catch {
      dispatchMovies({type: 'MOVIES_FETCH_FAILURE'})
    }
  }, [urls])
  
  React.useEffect(()=> {
    handleFetchMovies()
  }, [handleFetchMovies])

  const handleSearchInput = (event) => {
    setSearchTerm(event.target.value)
  }
  const handleSearchSubmit = (event) => {
    handleSearch(searchTerm)
    event.preventDefault()
  }
  const handleSearch = (searchTerm) => {
    const url = getUrl(searchTerm)
    setUrls(urls.concat(url))
  }
  return (
    <StyledContainer>
        <h4 className='sample-title'>Search&Filter Tool part1</h4>
        <div className='tag-container'>
        <span className='tech-tag'>React</span><span className='tech-tag'>Material UI</span><span className='tech-tag'>Redux</span>
        </div>
      <div className='sample-contents-container'>
      <div className='sample-title-container'>
        <div className='search-filter-part1'>
          <div>Movie Title</div>
          <SearchForm
            searchTerm={searchTerm}
            onSearchInput={handleSearchInput}
            onSearchSubmit={handleSearchSubmit}
          />
        </div>
        </div>
        <div className='table-container'>
          <h5 className='list-ttl'>Wordwide Lifetime Gross Record</h5>
          <div style={{justifyContent: 'space-between'}}>
          {movies.isError && <p>Something went wrong...</p>}
          {movies.isLoading? (<p>Loading...</p>):(
            <List 
            list={searchedMovies}/>
          )}
            </div>
            <p>Data Resource: <a href='https://www.boxofficemojo.com/chart/ww_top_lifetime_gross/?area=XWW&ref_=bo_cso_ac'>Box Office Mojo</a></p>
        </div>
      </div>
    </StyledContainer>
    )
}
export default ReduxWithOriginalData2