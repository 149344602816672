import React, {useState} from 'react'
import _ from 'lodash';
import { styled } from '@mui/material/styles'
import styled2 from 'styled-components'
import Slider from '@mui/material-next/Slider'
import './style.css'

const StyledContainer= styled2.div`
  padding: 0 20px;
  font-size: 12px;
`

const StyledItem = styled2.li`
 
`
const StyledColumn = styled2.span`
  padding: 0 5px;
  white-space: nowrap;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width:${(props)=>props.width};
  a {
    color: inherit;
  }
`
const moviesList = [
  {
    movie_title: 'Abatar',
    released_year: 2009,
    revenue: 2.84
  },
  {
    movie_title: 'Titanic',
    released_year: 1997,
    revenue: 2.18
  },
  {
    movie_title: 'Star Wars: The Force Awakens',
    released_year: 2015,
    revenue: 2.06
  },
  {
    movie_title: 'Spider-Man: No Way Home film currently playing',
    released_year: 2021,
    revenue: 1.90
  },
  {
    movie_title: 'Jurassic World',
    released_year: 2015,
    revenue: 1.67
  },
  {
    movie_title: 'The Lion King',
    released_year: 2019,
    revenue: 1.65
  },
  {
    movie_title: 'Star Wars: Episode I – The Phantom Menace',
    released_year: 1999,
    revenue: 1.02
  }
]
const useStyles = styled({
  root: {
      width: 125,
  }
});

function valuetext(value){
  return `{value}`;
}

function SliderComponent ({label, placeholder, onChange, initialRange, ...rest}){
  const classes = useStyles()
  const [value, setValue] = React.useState(initialRange||[0, 100])
  const handleChange = (event, newValue) => {
    setValue(newValue)
    onChange(newValue)
 }
  return (
  <div className={classes.root}>
    <Slider 
      value={value} 
      onChange={handleChange} 
      valueLabelDisplay='auto' 
      size="small"
      aria-label="Small steps"
      getArialValueText={valuetext} 
      {...rest} 
      className='range-filter'
    />
  </div>
  )
}
function searchByKeyword(moviesList, movieTitle) {
  return moviesList.filter(item => 
    item.movie_title.toLowerCase().includes(movieTitle.toLowerCase())
  )
}
function findReleasedYear(moviesList, min, max){
  const result = []
  for (let i = 0; i < moviesList.length; i++){
    const item = _.cloneDeep(moviesList)
    if(min <= item[i].released_year && item[i].released_year <= max){
      result.push(item[i])
    }
  }
  return result
}
function findRevenue(moviesList, min, max){
  const result = []
  for (let i = 0; i < moviesList.length; i++){
    const item = _.cloneDeep(moviesList)
    if(min <= item[i].revenue && item[i].revenue <= max){
      result.push(item[i])
    }
  }
  return result
}
const Item = ({item}) => {
  return (
      <li className='movie_item'>
      <StyledColumn width='60%'>{item.movie_title}</StyledColumn><br/>
      <StyledColumn width='20%'>{item.released_year}</StyledColumn><br/>
      <StyledColumn width='20%'>${item.revenue}Billion</StyledColumn>
      </li>
  )
}
function SearchFilter2() {
  const [items, setItems] = useState(moviesList)
  const [movieTitle, setMovieTitle] = useState('')
  
  const searchMovieTitle = e => {
    setMovieTitle(e.target.value)
    const result = searchByKeyword(moviesList, e.target.value)
    setItems(result)
  }
  
  const handleChangeYear =  value => {
    const result = findReleasedYear(moviesList, value[0], value[1])
    setItems(result)
  }
  const handleChangeAmount =  value => {
    const result = findRevenue(moviesList, value[0], value[1])
    setItems(result)
  }
  return (
    <StyledContainer>
        <h4 className='sample-title'>Search&Filter Tool part2</h4>
        <div className='tag-container'>
        <span className='tech-tag'>React</span><span className='tech-tag'>Material UI</span>
        </div>
      <div className='sample-contents-container'>
      <div className='sample-title-container'>
        <div className='search-filter-part2'>
          <div>Movie Title</div>
          <input type='text' value={movieTitle} onChange={searchMovieTitle} className='search-bar'/>
          <div>Released Year</div>
          <SliderComponent min={1990} max={2022} initialRange={[1997, 2001]} onChange={handleChangeYear} step={1}/>
          <div>Total Gross</div>
          <SliderComponent min={1} max={3} initialRange={[1.02, 2.84]} onChange={handleChangeAmount} step={0.01}/>
        </div>
      </div>
      <div className='table-container'>
        <h5 className='list-ttl'>Wordwide Lifetime Gross Record</h5>
        <div style={{justifyContent: 'space-between'}}>
        {
          items.map(item => <Item item={item}/>)
        }
        </div>
        <p>Data Resource: <a href='https://www.boxofficemojo.com/chart/ww_top_lifetime_gross/?area=XWW&ref_=bo_cso_ac'>Box Office Mojo</a></p>
    </div>
    </div>
  </StyledContainer>
  )
}

export default SearchFilter2