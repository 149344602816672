import React, { useEffect } from 'react';
import { PieChart, Pie, Cell } from 'recharts';
import './style.css'
import OutlinedInput from '@mui/material/OutlinedInput'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import { FiSmile } from 'react-icons/fi'

const baseURL = process.env.NODE_ENV === 'production' ? `${process.env.REACT_APP_DB_ENV}/api/pg/favoritecolor` : `${process.env.REACT_APP_DB_ENV_TEST}/api/pg/favoritecolor`
const postURL = process.env.NODE_ENV === 'production' ? `${process.env.REACT_APP_DB_ENV}/api/pg/favoritecolor/add` : `${process.env.REACT_APP_DB_ENV_TEST}/api/pg/favoritecolor/add`
const deleteURL = process.env.NODE_ENV === 'production' ? `${process.env.REACT_APP_DB_ENV}/api/pg/favoritecolor/remove/` : `${process.env.REACT_APP_DB_ENV_TEST}/api/pg/remove/`
const totalURL = process.env.NODE_ENV === 'production' ? `${process.env.REACT_APP_DB_ENV}/api/pg/totalvoters` : `${process.env.REACT_APP_DB_ENV_TEST}/api/pg/totalvoters`
const resultURL = process.env.NODE_ENV === 'production' ? `${process.env.REACT_APP_DB_ENV}/api/pg/totalvotersbycolor` : `${process.env.REACT_APP_DB_ENV_TEST}/api/pg/totalvotersbycolor`

const votingColor = [{ name: "Navy" }, { name: "Mint" }]
const colors = ['#606277', '#D9D9D9', '#bdbdbd'];
console.log('process.env.NODE_ENV', process.env.NODE_ENV)
const renderCustomizedLabel = ({ cx, cy, rx, ry, midAngle, innerRadius, outerRadius, payload, percent, fill, index }) => {
  const radius = innerRadius + (outerRadius - innerRadius) * .5
  const x = cx + radius * Math.cos(-midAngle * Math.PI / 180)
  const y = cy + radius * Math.sin(-midAngle * Math.PI / 180)
  return (
    <>
      <circle x={x} y={y} r={2} fill={fill} stroke="none" />
      <text x={x} y={y - 10} fill='white' className='pie-label' textAnchor={x > cx ? 'start' : 'end'} dominantBaseline='central'>
        {`${payload.name}`}
      </text>
      <text x={x} y={y - 20} fill='white' className='pie-label' textAnchor={x > cx ? 'start' : 'end'} dominantBaseline='central'>{`${Math.floor(percent * 100)}%`}
      </text>
    </>
  )
}
class PieChartGenerator extends React.Component {
  constructor() {
    super()
    this.state = {
      portfolioPieChart: [],
      username: '',
      name: '',
      id: '',
      isMessageVisible: false,
      color: [],
      value: '',
      totalvoters: [],
      total_voters: '',
      update:0
    }
    this.pieSelectHandleChange = this.pieSelectHandleChange.bind(this)
  } 
  componentDidUpdate(prevState) {
    if (this.state.update !== 0 ){
    var that = this
    //vote items
    fetch(baseURL)
    .then(function (response) {response.json()
    .then(function (data) {that.setState({portfolioPieChart: data})})
    })
    //vote result
    fetch(resultURL)
    .then(function (response) {response.json()
    .then(function (data) {that.setState({color: data})})
    })
    //vote total
    fetch(totalURL)
    .then(function (response) {response.json()
    .then(function (data) {that.setState({totalvoters: data})})
    })
    that.setState(prevState => ({update: prevState.update - 1}))
    } else {return}
  }
  componentDidMount() {
    var that = this
    //vote items
    fetch(baseURL)
    .then(function (response) {response.json()
    .then(function (data) {that.setState({portfolioPieChart: data})})
    })
    //vote result
    fetch(resultURL)
    .then(function (response) {response.json()
    .then(function (data) {that.setState({color: data})})
    })
    //vote total
    fetch(totalURL)
    .then(function (response) {response.json()
    .then(function (data) {that.setState({totalvoters: data})})
    })
  }
  addVote(e) {
      e.preventDefault()
      let favoritecolor_data = {
        username: this.state.username,
        name: this.state.name,
        id: Math.random().toFixed(3),
        value: this.state.value,
        total_voters: this.state.total_voters
      }
      var request = new Request(postURL, {
        method: 'POST',
        headers: new Headers({ 'Content-Type': 'application/json' }),
        body: JSON.stringify(favoritecolor_data)
      })
      let portfolioPieChart = this.state.portfolioPieChart
      let color = this.state.color
      let totalvoters = this.state.totalvoters
      portfolioPieChart.push(favoritecolor_data)
      this.setState({
        portfolioPieChart: portfolioPieChart,
        color: color,
        totalvoters: totalvoters
      })
      fetch(request)
      .then(function (response) {response.json()
      .then(function (favoritecolor_data) {})
      })
      var that = this
      fetch(resultURL)
      .then(function (response) {response.json()
      .then(function (data) {that.setState({color: data})})
      })
      fetch(totalURL)
      .then(function (response) {response.json()
      .then(function (data) {that.setState({totalvoters: data})})
      })
      .catch(function (err) {console.log(err)})
      that.setState(prevState => ({update: prevState.update + 1}))
      this.setState({
        username: '',
        name: '',
        isMessageVisible: true,
      })
    setTimeout(() => this.setState({ isMessageVisible: false }), 5000)
  }
  pieInputHandleChange = e => {
    this.setState({
      [e.target.name]: e.target.value,
      unique: true
    })
  }
  pieSelectHandleChange = e => {this.setState({ name: e.target.value })}
  isClickable = e => {
    const valid = this.state.username.length > 0 && this.state.name !== 0
    return valid
  }
  removeVote(id) {
    var that = this
    let portfolioPieChart = this.state.portfolioPieChart
    let favoritecolor = portfolioPieChart.find(function (favoritecolor) {
      return favoritecolor.id === id
    })
    var request = new Request(deleteURL + id, {
      method: 'DELETE'
    })
    fetch(request)
    .then(function (response) {
        portfolioPieChart.splice(portfolioPieChart.indexOf(favoritecolor), 1)
        that.setState({
          portfolioPieChart: portfolioPieChart
        })
        response.json()
          .then(function (data) {
            console.log(data)
          })
      })
    //vote result
    fetch(resultURL)
      .then(function (response) {
        response.json()
          .then(function (data) {
            that.setState({
              color: data
            })
          })
      })
    // vote total
    fetch(totalURL)
      .then(function (response) {
        response.json()
          .then(function (data) {
            that.setState({
              totalvoters: data
            })
          })
      })
      that.setState((prevState) => {return {update: prevState.update + 1}})
  }
  render() {
    for (var i = 0; i < this.state.color.length; i++) {
      var obj = this.state.color[i];
      for (var prop in obj) {
        if (obj.hasOwnProperty(prop) && obj[prop] !== null && !isNaN(obj[prop])) {
          obj[prop] = +obj[prop];
        }
      }
    }
    return (
      <div className='sample-container'>
        <h4 className='sample-title'>Pie Chart Generator</h4>
        <div className='tag-container'>
          <span className='tech-tag'>React</span><span className='tech-tag'>Recharts</span><span className='tech-tag'>DB: PostgreSQL</span>
        </div>
        <div className='sample-contents-container-005'>
          <div className='sample-input-container'>
            <FormControl sx={{ m: 1, width: 250, mt: 3 }} lg={{ m: 1, width: 250, mt: 3 }}>
              Username<br />
              <input
                type="text"
                name='username'
                onChange={this.pieInputHandleChange}
                value={this.state.username}
                placeholder='your nickname'
                className="piedata-input-container"
              />
              Select your favorite color<br />
              <Select
                defaultValue={1}
                displayEmpty
                renderValue={(selected) => {
                  if (selected.length === 0) {
                    return <div className='placeholder'>select color</div>;
                  }
                  return selected
                }}
                type='select'
                input={<OutlinedInput />}
                name='name'
                onChange={this.pieSelectHandleChange}
                value={this.state.name}
                className="piedata-select-container"
                inputProps={{ 'aria-label': 'Without label' }}
              >
                <MenuItem disabled value="">
                  <em>select color</em>
                </MenuItem>
                {votingColor.map((x) => (
                  <MenuItem
                    value={x.name}
                  >{x.name}</MenuItem>
                ))}
              </Select>
              <div className='piedata-btn-container'>
                <button
                  type='submit'
                  onClick={this.addVote.bind(this)}
                  disabled={!this.isClickable()}
                  className='piedata-btn'
                >Vote!</button><br />
                {this.state.isMessageVisible && <p className='hidden-message'>Thanks for voting! <FiSmile /></p>}
              </div>
            </FormControl>
          </div>
          <div className='table-container'>
            <h5 className='list-ttl'>Data Result:</h5>
            <PieChart width={200} height={200}>
              <Pie
                data={this.state.color}
                labelLine={false}
                label={renderCustomizedLabel}
                outerRadius={80}
                startAngle={90}
                endAngle={-360}
                fill="#8884d8"
                dataKey="value"
                paddingAngle={0}
                innerRadius={0}
                activeShape={renderCustomizedLabel}
              >
                {this.state.color.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={colors[index % colors.length]} />
                ))}
              </Pie>
            </PieChart>
            <div className='result-data-container'>
              <div></div>
              <div>
                Results:<br />
                <ul className="contributors_list">{this.state.color.map(color => <li key={color.name}>
                  {color.name}
                  {color.value}</li>)}
                </ul>
                {this.state.totalvoters.map(totalvoter => <>{totalvoter.total_voters}</>)} people voted<br /><br />
                Contributors:<br />
                {this.state.portfolioPieChart.map(favoritecolor => <span key={favoritecolor.id}>
                  {favoritecolor.username}
                  {favoritecolor.name}
                  <button className="contributors_list_delete_icon" onClick={this.removeVote.bind(this, favoritecolor.id)}>x</button>
                </span>)}<br />
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
export default PieChartGenerator