import React from 'react'; 
import { ScatterChart, CartesianGrid, XAxis, YAxis, ZAxis, Tooltip, Legend, Scatter, ReferenceLine} from 'recharts'
import styled from 'styled-components'
import './style.css'
// import MenuItem from '@mui/material/MenuItem'
// import FormControl from '@mui/material/FormControl'
// import Select from '@mui/material/Select'

const data01 = [
  {
    "x":100,
    "y":100,
    "z":100
  },
  {
    "x":50,
    "y":60,
    "z":100
  },
  {
    "x":0,
    "y":100,
    "z":100
  },
  {
    "x":-100,
    "y":-100,
    "z":100
  },
]
const data02 = [
  {
    "x":-50,
    "y":100,
    "z":100
  },
  {
    "x":40,
    "y":25,
    "z":100
  },
  {
    "x":100,
    "y":-50,
    "z":100
  },
]

class PlotGenerator extends React.Component {
    render (){
      
      return (
                <div className='sample-container'>
                    <h4 className='sample-title'>Plot Generator</h4>
                    <div className='tag-container'>
                    <span className='tech-tag'>React</span><span className='tech-tag'>postgreSQL</span><span className='tech-tag'>Recharts</span>
                    </div>
                  <div className='sample-contents-container-007'>
                    <div className='sample-input-container'>
                    select stocks<br/>
                    x-axis<br/>
                    y-axis
                    </div>
                    <div className='table-container'>
                      <h5 className='list-ttl'>Data Result:</h5>
                      <div className='result-data-container'>
                      <div></div>
                      <div>
                        <ScatterChart width={400} height={250}
                          margin={{ top: 20, right: 20, bottom: 10, left: 10 }}>
                          <CartesianGrid strokeDasharray="3 3" />
                          <XAxis dataKey="x" type="number" unit="US$" 
                          domain={[-100, 100]}
                         //  domain={['dataMin', 'dataMax']}
                          />
                          <YAxis dataKey="y" name="number" unit="US$" />
                          {/* <ZAxis dataKey="z" 
                            range={[64, 144]} 
                            // range={[-150, 150]} 
                            name="dot" 
                            unit="unit" 
                            /> */}
                          <Tooltip cursor={{ strokeDasharray: '3 3' }} />
                          <Legend />
                          <Scatter name="Google" data={data01} shape="circle" fill="#d9d9d9" />
                          <Scatter name="Tesla" data={data02} fill="#606277" />
                          <ReferenceLine x={0} stroke="grey" />
                          <ReferenceLine y={0} stroke="grey" />
                          <ReferenceLine stroke="red" strokeDasharray="3 3" segment={[{ x: 0, y: 0 }, { x: 0, y: 0 }]} />
                        </ScatterChart>
                        </div>
                        </div>
                      </div>
                    </div>
                </div>
      )
    }
}

export default PlotGenerator