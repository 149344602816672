import React, { useState, useEffect } from 'react'
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts'
import OutlinedInput from '@mui/material/OutlinedInput'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import styled from 'styled-components'
import './style.css'
import axios from 'axios'
const fetch = require('node-fetch')
//import { FiEdit } from 'react-icons/fi'
const APIURL = process.env.NODE_ENV === 'production' ? `${process.env.REACT_APP_DB_ENV}` : `${process.env.REACT_APP_DB_ENV_TEST}`
console.log('process.env.NODE_ENV', process.env.NODE_ENV, APIURL)
var defaultLinearGraphData = [
    {
        tableID: 1,
        title: 'Meta Platforms Inc',
        apiStockName: 'META',
        resourceTitle: 'Google Finance',
        url: 'https://www.google.com/finance/quote/META:NASDAQ?hl=en',
        updateddate: new Date().toLocaleDateString("en-US"),
        items20: [{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 }],
        items3M: [{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 }],
        items6M: [{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 }],
        items1Y: [{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 }],
        items3Y: [{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 }],
        items6Y: [{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 }]
    },
    {
        tableID: 2,
        title: 'Amazon.com Inc',
        apiStockName: 'AMZN',
        resourceTitle: 'Google Finance',
        url: 'https://www.google.com/finance/quote/AMZN:NASDAQ?hl=en',
        updateddate: new Date().toLocaleDateString("en-US"),
        items20: [{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 }],
        items3M: [{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 }],
        items6M: [{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 }],
        items1Y: [{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 }],
        items3Y: [{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 }],
        items6Y: [{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 }]
    },
    {
        tableID: 3,
        title: 'Apple Inc',
        apiStockName: 'AAPL',
        resourceTitle: 'Google Finance',
        url: 'https://www.google.com/finance/quote/AAPL:NASDAQ?hl=en',
        updateddate: new Date().toLocaleDateString("en-US"),
        items20: [{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 }],
        items3M: [{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 }],
        items6M: [{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 }],
        items1Y: [{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 }],
        items3Y: [{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 }],
        items6Y: [{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 }]
    },
    {
        tableID: 4,
        title: 'Alphabet Inc Class A',
        apiStockName: 'GOOGL',
        resourceTitle: 'Google Finance',
        url: 'https://www.google.com/finance/quote/GOOGL:NASDAQ?hl=en',
        updateddate: new Date().toLocaleDateString("en-US"),
        items20: [{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 }],
        items3M: [{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 }],
        items6M: [{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 }],
        items1Y: [{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 }],
        items3Y: [{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 }],
        items6Y: [{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 }]
    },
    {
        tableID: 5,
        title: 'NVIDIA Corp',
        apiStockName: 'NVDA',
        resourceTitle: 'Google Finance',
        url: 'https://www.google.com/finance/quote/NVDA:NASDAQ?hl=en',
        updateddate: new Date().toLocaleDateString("en-US"),
        items20: [{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 }],
        items3M: [{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 }],
        items6M: [{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 }],
        items1Y: [{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 }],
        items3Y: [{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 }],
        items6Y: [{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 }]
    },
    {
        tableID: 6,
        title: 'Microsoft Corp',
        apiStockName: 'MSFT',
        resourceTitle: 'Google Finance',
        url: 'https://www.google.com/finance/quote/MSFT:NASDAQ?hl=en',
        updateddate: new Date().toLocaleDateString("en-US"),
        items20: [{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 }],
        items3M: [{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 }],
        items6M: [{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 }],
        items1Y: [{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 }],
        items3Y: [{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 }],
        items6Y: [{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 }]
    },
    {
        tableID: 7,
        title: 'Tesla Inc',
        apiStockName: 'TSLA',
        resourceTitle: 'Google Finance',
        url: 'https://www.google.com/finance/quote/TSLA:NASDAQ?hl=en',
        updateddate: new Date().toLocaleDateString("en-US"),
        items20: [{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 }],
        items3M: [{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 }],
        items6M: [{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 }],
        items1Y: [{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 }],
        items3Y: [{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 }],
        items6Y: [{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 }]
    }
]
const StyledContainer = styled.div`
  padding: 0 20px;
  font-size: 12px;
`
const currency = (tableID) => {
    if (tableID === 1 || 2) {
        return "US$"
    }
    if (tableID === 3) {
        return "€"
    }
    return ''
}
const HoverLabel = ({ active, payload, tableID, label }) => {
    if (active && payload && payload.length) {
        return (
            <div className="hover-label">
                {`${label} ${currency(tableID)}${payload[0].value}`}
            </div>
        )
    }
    return null
}
export default function LinearGenerator() {
    const [selectedData, setSelectedData] = useState('3M')
    const [selectedApiStockName1, setSelectedApiStockName1] = useState(defaultLinearGraphData[0].apiStockName)
    const [linearGraphData, setLinearGraphData] = useState(defaultLinearGraphData)
    const [selectedApiStockItemIndex1, setSelectedApiStockIndex1] = useState(5)
    const [selectedStock, setSelectedStock] = useState(defaultLinearGraphData[0])
    const [stockUpdateRequired, setStockUpdateRequired] = useState(false) 
    const [maxRange, setMaxRange] = useState(500)
    const term = [{ttl: '3M'},{ttl: '6M'},{ttl: '1Y'},{ttl: '3Y'},{ttl: '6Y'}]
    useEffect(() => {
        const fetchData = async () => {
            await axios.all([axios.get(`${APIURL}/api/pg/nasdaqstocks`),axios.get(`${APIURL}/api/pg/nasdaqstockvalues`)]).then(axios.spread(function(res1, res2) {
            const data1 = res1.data
            const data2 = res2.data
            console.log('----!!!stockUpdateRequired 1!!---',stockUpdateRequired, new Date().toLocaleDateString("en-US"), data1[0].updateddate)
            if (new Date().toLocaleDateString("en-US") !== data1[0].updateddate){
                setStockUpdateRequired(true)
                console.log('stockUpdateRequired 2',stockUpdateRequired, new Date().toLocaleDateString("en-US"), data1[0].updateddate)
                onClick2UpdateAPI()
                console.log('auto clicked API button') 
            }else{
                setStockUpdateRequired(false)
            }
            var M3 = ['m3', 'm2_5', 'm2', 'm1_5', 'm1', 'm0_5', 'm0']
            var M6 = ['m6', 'm5', 'm4', 'm3', 'm2', 'm1', 'm0']
            var Y1 = ['y1', 'm10', 'm8', 'm6', 'm4', 'm2', 'm0']
            var Y3 = ['y3', 'y2_5', 'y2', 'y1_5', 'y1', 'm6', 'm0']
            var Y6 = ['y6', 'y5', 'y4', 'y3', 'y2', 'y1', 'm0']
            //loop 0 to 6
            for(let i = 0; i <= 6; i++){   
            const stockData = data2.filter((x) => x.apistockname === data1[i].apistockname)
            data1[i]['items20'] = data2.filter((x) => x.apistockname ===data1[i].apistockname)
            data1[i]['items3M'] = stockData.filter(function(x){if(M3.includes(x.itemterm)){return x}})
            data1[i]['items6M'] = stockData.filter(function(x){if(M6.includes(x.itemterm)){return x}})
            data1[i]['items1Y'] = stockData.filter(function(x){if(Y1.includes(x.itemterm)){return x}})
            data1[i]['items3Y'] = stockData.filter(function(x){if(Y3.includes(x.itemterm)){return x}})
            data1[i]['items6Y'] = stockData.filter(function(x){if(Y6.includes(x.itemterm)){return x}})
            console.log(i,'update!!!',data1[i])
             }
            setLinearGraphData(data1)
            console.log('----updated data1------', data1[selectedApiStockItemIndex1].items3M[6].name)
            console.log('----updated data2------', linearGraphData[selectedApiStockItemIndex1])
            // .items3M[5].name
            console.log('----updated data3------', linearGraphData)
            setSelectedStock(data1[0])
            console.log('----selectedStock------', selectedStock)
            }))
        }
        fetchData()
    },[])
    const onClick2Term = (e, ttl) => {
        e.preventDefault()
        setSelectedData(ttl)
    }
    const onChange2StockName = async (e) => {
        // console.log('----updated data!!!------', linearGraphData[selectedApiStockItemIndex1])
        // console.log('----updated data!!!------',linearGraphData[selectedApiStockItemIndex1].name, 0, `${Math.max(...(linearGraphData[selectedApiStockItemIndex1].items6Y.map(x=>x.value)))}`)
        console.log('----updated data!!!------',Math.max(...selectedStock[`items${selectedData}`].map(x=>Number(x.value))))
        await setSelectedApiStockIndex1(e.target.value - 1)
        await setSelectedStock(linearGraphData[e.target.value - 1])
        await setSelectedApiStockName1(linearGraphData[e.target.value - 1].apistockname)
    }
    const onClick2UpdateAPI = async() => {
        async function getAPI(stockSymbol){
            try{
                const res = await fetch(process.env.REACT_APP_YAHOO_FINANCE_ENV, {
                method:'POST',
                headers: {
                    'content-type': 'application/x-www-form-urlencoded',
                    'X-RapidAPI-Key': process.env.REACT_APP_X_RAPID_API_KEY,
                    'X-RapidAPI-Host': process.env.REACT_APP_X_RAPID_API_HOST
                },
                body: new URLSearchParams({
                    symbol: stockSymbol,
                    period: '10y'
                })
                })
                console.log('selected x',stockSymbol)
                await res.json()
                // const result = await res.text();
                // console.log(result)
                .then(function (x) {
                if (x.status = 200) {
                    console.log('Update API button clicked 1',stockSymbol,' :', x.data)
                    const APIdata = x.data
                    //1 get 10y item
                    function findItem1(x1){
                        var m = (new Date()).setMonth((new Date()).getMonth() - x1)
                        const a2 = APIdata.find((x) => new Date(x.Date).toString().slice(4,15)=== new Date(m).toString().slice(4,15))
                        for (var x2 = 1; a2 === undefined; x2++) {
                        m = (new Date(m)).setDate((new Date()).getDate() - x2)
                        const a2 = APIdata.find((x) => new Date(x.Date).toString().slice(4,15)=== new Date(m).toString().slice(4,15))     
                        if(a2 !== undefined){
                            return a2  
                        }
                        }
                        return a2
                    }
                    function findItem2(x1){
                        var m = (new Date()).setMonth((new Date()).getMonth() - x1)
                        m = (new Date(m)).setDate((new Date()).getDate() - 15)
                        const a2 = APIdata.find((x) => new Date(x.Date).toString().slice(4,15)=== new Date(m).toString().slice(4,15))
                        for (var x2 = 15; a2 === undefined; x2++) {
                        var m = (new Date()).setMonth((new Date()).getMonth() - x1)
                        m = (new Date(m)).setDate((new Date()).getDate() - x2)
                        const a2 = APIdata.find((x) => new Date(x.Date).toString().slice(4,15)=== new Date(m).toString().slice(4,15))     
                        if(a2 !== undefined){
                            return a2  
                        }
                        }
                        return a2
                    }
                    function findItem3(x1){
                        var x3 = (new Date()).setFullYear((new Date()).getFullYear() - x1)
                        const a2 = APIdata.find((x) => new Date(x.Date).toString().slice(4,15)=== new Date(x3).toString().slice(4,15))
                        for (var x2 = 1; a2 === undefined; x2++) {
                        x3 = (new Date(x3)).setDate((new Date()).getDate() - x2)
                        const a2 = APIdata.find((x) => new Date(x.Date).toString().slice(4,15)=== new Date(x3).toString().slice(4,15))     
                        if(a2 !== undefined){
                            return a2  
                        }
                        }
                        return a2
                    }
                    function findItem4(x1){
                        var m = (new Date()).setFullYear((new Date()).getFullYear() - x1)
                        m = (new Date(m)).setMonth((new Date()).getMonth() - 6)
                        const a2 = APIdata.find((x) => new Date(x.Date).toString().slice(4,15)=== new Date(m).toString().slice(4,15))
                        for (var x2 = 1; a2 === undefined; x2++) {
                        m = (new Date(m)).setDate((new Date()).getDate() - x2)
                        const a2 = APIdata.find((x) => new Date(x.Date).toString().slice(4,15)=== new Date(m).toString().slice(4,15))     
                        if(a2 !== undefined){
                            return a2  
                        }
                        }
                        return a2
                    }
                    const today = new Date(Date.now()).toString().slice(4,15)
                    console.log("1. get 5month item itemnumber:8-13")
                    const y6 = findItem3(6) //1
                    const y5 = findItem3(5) //2
                    const y4 = findItem3(4) //3
                    const y3 = findItem3(3) //4
                    const y2_5 = findItem4(2) //5
                    const y2 = findItem3(2) //6
                    const y1_5 = findItem4(1) //7
                    const y1 = findItem3(1) //8
                    const m10 = findItem1(10) //9
                    const m8 = findItem1(8) //10
                    const m6 = findItem1(6) //11
                    const m5 = findItem1(5) //12
                    const m4 = findItem1(4)  //13
                    const m3 = findItem1(3)  //14
                    const m2_5 = findItem2(2) //15
                    const m2 = findItem1(2) //16
                    const m1_5 = findItem2(1) //17
                    const m1 = findItem1(1) //18
                    const m0_5 = findItem2(0) //19
                    const m0 = findItem1(0) //20
                    var Items20 = [y6, y5, y4, y3, y2_5, y2, y1_5, y1, m10, m8, m6, m5, m4, m3, m2_5, m2, m1_5, m1, m0_5, m0]
                    function trimItem(x1){
                        //2 display year only one time
                        const step1 = x1.map(x => {
                            const y = {}
                            y.date = x.Date, 
                            // y.name = new Date(x.Date).toISOString().substring(5, 10)
                            y.name = new Date(x.Date).toISOString().substring(0, 10)
                            y.value = (Math.round((x.Close) * 100).toFixed(2) / 100)
                            return y 
                        }).sort((a, b) => a.date - b.date)
                        //.slice(6, 12)
                        const step2 = [...new Map(x1.reverse().map(x => [new Date(x.Date).getYear(), x])).values()].map(x => {
                            const y = {}
                            y.date = x.Date
                            y.name = new Date(x.Date).toISOString().substring(0, 10)
                            y.value = (Math.round((x.Close) * 100).toFixed(2) / 100)
                            return y
                        })
                        // //3 combine together
                        const step3 = step1.concat(step2)
                        console.log("-----step3!!!!!-----",step3)
                        step3.sort((a, b) => a.date - b.date)
                        // //4 emit duplicated item
                        const step4 = [...new Map(step3.map(x => [x.date, x])).values()].map(x => {
                        const y = {}
                        y.date = x.date
                        y.name = x.name
                        y.value = x.value
                        return y
                        })
                        return step4
                    }
                    var stockData = trimItem(Items20)
                    console.log('Update API button clicked 1',x,' :', stockSymbol, stockData)
                    axios.post(`${APIURL}/api/pg/nasdaqstockvalues`, {apistockname: stockSymbol, stocks: stockData})
                    setStockUpdateRequired(false)
                }
            })
        } catch (err) {
            console.log(stockSymbol,' api data:',err)
        }
        }
        getAPI('META')
        setTimeout(() => {getAPI('AMZN')}, 3000)
        setTimeout(() => {getAPI('AAPL')}, 6000)
        setTimeout(() => {getAPI('MSFT')}, 8000)
        setTimeout(() => {getAPI('TSLA')}, 12000)
        setTimeout(() => {getAPI('GOOGL')}, 14000)
        setTimeout(() => {getAPI('NVDA')}, 16000) 
        setTimeout(async () => {
            console.log('update as', new Date().toLocaleDateString("en-US"))
            await axios.post(`${APIURL}/api/pg/nasdaqstocks`, {updateddate: new Date().toLocaleDateString("en-US")}).then(function (x) {console.log(x.data.msg)})
        }, 18000)
    }
    return (
        <StyledContainer>
            <h4 className='sample-title'>Linear Generator</h4>
            <div className='tag-container'>
                <span className='tech-tag'>React</span><span className='tech-tag'>Recharts</span><span className='tech-tag'>API: Rapid API Yahoo Finance</span><span className='tech-tag'>DB: PostgreSQL</span>
            </div>
            <div className='sample-contents-container-006'>
                <div className='sample-title-container-006'>
                    <FormControl className='select-field' sx={{ m: 1, mt: 3 }} lg={{ m: 1, mt: 3 }}>
                        Select Nasaq stock
                        <Select
                            defaultValue={6}
                            onChange={onChange2StockName}
                            input={<OutlinedInput />}
                            className="table-title-container"
                            inputProps={{ 'aria-label': 'Without label' }}
                        >
                            {linearGraphData.map((x) => (
                                <MenuItem value={x.tableID}>{x.title}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <div>
                        Data Resource : {selectedStock.resourceTitle}<br />
                        <a href={selectedStock.url}>▶︎ Click here to see the original data</a>
                    </div>
                    Rate on {linearGraphData[selectedApiStockItemIndex1].items3M[6].name}:<h2>US${linearGraphData[selectedApiStockItemIndex1].items3M[6].value}</h2>
                    <button onClick={onClick2UpdateAPI} className='update-btn' disabled={!(stockUpdateRequired)}>Update Yahoo Finace API data(takes 30s)</button>
                </div>
                <div className='sample-table-container-006'>
                    <h4>Data Result :</h4>
                    <div className='rate-tab'>{term.map((x, i) => (<button key={i} onClick={(e) => onClick2Term(e, x.ttl)} className={selectedData === x.ttl ? 'term-ttl-active' : undefined}
                >{x.ttl}</button>
                ))}</div>
                    <div className='graph-container'>
                    {term.map((x, i) => x.ttl === selectedData && <div key={i}>{x.data}</div>)}
                        <LineChart className='line-graph-pc' width={500} height={200}>
                        <CartesianGrid vertical={false} stroke="#f5f5f5" />
                        <XAxis dataKey="name" type="category" allowDuplicatedCategory={false} />
                        <YAxis dataKey="value" type="number" tickCount="9" 
                        allowDataOverflow domain={[ 0, Math.round(Math.max(...selectedStock[`items${selectedData}`].map(x=>Number(x.value))))+100]} 
                        //domain={[ 0, 'auto']} 
                        />
                        <Tooltip content={<HoverLabel />}/>
                        <Line
                            dataKey="value"
                            data={selectedStock[`items${selectedData}`]}
                            type="linear"
                            stroke="#000"
                        />
                        </LineChart>
                        <ResponsiveContainer width="100%" height="100%">
                        <LineChart className='line-graph-sp'
                        width={250}
                        height={100}
                        >
                        <CartesianGrid vertical={false} stroke="#f5f5f5" />
                        <XAxis dataKey="name" type="category" allowDuplicatedCategory={false} />
                        <YAxis dataKey="value" type="number" allowDataOverflow tickCount="9" domain={[ 0, Math.round(Math.max(...selectedStock[`items${selectedData}`].map(x=>Number(x.value))))+100]}/>
                        <Tooltip content={<HoverLabel />}/>
                        <Line dataKey="value" data={selectedStock[`items${selectedData}`]}
                            type="linear"
                            stroke="#000"
                        />
                        </LineChart>
                        </ResponsiveContainer>
                    </div>
                </div>
            </div>
        </StyledContainer >
    )
}
