import React, { useState, useRef, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import './style.css'
import axios from 'axios'
import { FiLogOut } from 'react-icons/fi'
import { AiFillGoogleCircle, AiOutlineDoubleLeft, AiOutlineDoubleRight } from 'react-icons/ai'
import { MdEdit, MdEditOff } from 'react-icons/md'
import { renderToStaticMarkup } from 'react-dom/server'
import { IoIosClose } from 'react-icons/io'
import { IoTimeSharp, IoCloseCircleSharp } from 'react-icons/io5'
import { GiConsoleController } from 'react-icons/gi'
// **firebase**
import { db, auth, googleProvider, storage } from '../../firebase'
import { createUserWithEmailAndPassword, signInWithEmailAndPassword, getAuth, reauthenticateWithCredential, onAuthStateChanged, signOut, signInWithPopup, GoogleAuthProvider, updateEmail, updateProfile, updatePassword, deleteUser, sendPasswordResetEmail, confirmPasswordReset, sendSignInLinkToEmail } from 'firebase/auth'
import { getFirestore, doc, collection, getDocs, setDoc, deleteDoc,  onSnapshot, query, where, serverTimestamp } from 'firebase/firestore'
import {set} from 'firebase/database'
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage'


const svgStringMdEdit = encodeURIComponent(renderToStaticMarkup(<MdEdit />))
const svgStringIoIosClose = encodeURIComponent(renderToStaticMarkup(<IoIosClose />))
console.log('process.env.NODE_ENV', process.env.NODE_ENV)
const APIURL = process.env.NODE_ENV === 'production' ? `${process.env.REACT_APP_DB_ENV}` : `${process.env.REACT_APP_DB_ENV_TEST}`
function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}
CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
}
// **mongodb admin roles**
const ROLE = {
  ADMIN: 'admin',
  BASIC: 'basic',
}
export const LoginWithDifferentDB = () => {
  const [testAccountDetail, setTestAccountDetail] = useState(false)
  const [connectCheckBackend, setConnectCheckBackend] = useState(null)
  const [connectCheckPG, setConnectCheckPG] = useState(null)
  const [connectCheckSQL, setConnectCheckSQL] = useState(null)
  const [currentUser, setCurrentUser] = useState()
  const [fbLoginData3, setFbLoginData3] = useState(null)
  const [pgLoginData3, setPgLoginData3] = useState(null)
  const [sqlLoginData3, setSqlLoginData3] = useState(null)
  const [mongoLoginData3, setMongoLoginData3] = useState(null)
  const fbToken = localStorage.getItem('fb_token')
  const pgToken = localStorage.getItem('pg_token')
  const sqlToken = localStorage.getItem('sql_token')
  const mongoToken = localStorage.getItem('mongodb_token')
  const [selectedTable, setSelectedTable] = useState('Firebase')
  //User data
  const Ref = useRef(null)
  useEffect(
    () => {
      const fetchData = async () => {
        axios.get(`${APIURL}/api`)
          .then(res => {
            setConnectCheckBackend(res.data)
          })
          axios.get(`${APIURL}/api/pg`)
          .then(res => {
            setConnectCheckPG(res.data)
            if(pgToken !== null){
              console.log('send pg token to check if it is expired', pgToken)
            }
          })
        axios.get(`${APIURL}/api/mysql`)
          .then(res => {
            setConnectCheckSQL(res.data)
          })
      }
      fetchData()
      const unsub = onAuthStateChanged(auth, user => setCurrentUser(user))
      return unsub
    }, []
  )
  const fbLoginData2 = (x) => {setFbLoginData3(x)}
  const pgLoginData2 = (x) => {setPgLoginData3(x)}
  const sqlLoginData2 = (x) => {setSqlLoginData3(x)}
  const mongoLoginData2 = (x) => {setMongoLoginData3(x)}
  const optionTabs = [
    {
      ttl: 'Firebase',
      table: <>{fbToken ? <FB fbLoginData1={fbLoginData3} /> : <><FBLogin
        fbLoginData1={fbLoginData2} /></>
      }</>
    },
    {
      ttl: 'PostgreSQL',
      table: <>{pgToken ? <PG pgLoginData1={pgLoginData3}
      /> : <><PGLogin pgLoginData1={pgLoginData2} /></>}</>
    },
    {
      ttl: 'MySQL',
      table: <>{sqlToken ? <SQL sqlLoginData1={sqlLoginData3} /> : <><SQLLogin sqlLoginData1={sqlLoginData2} /></>}</>
    },
    {
      ttl: 'MongoDB',
      table: <>{mongoToken ? <MONGO mongoLoginData1={mongoLoginData3} /> : <><MONGOLogin mongoLoginData1={mongoLoginData2} /></>}</>
    }
  ]
  return (
    <div className='sample-container-002'>
      <h4 className='sample-title'>Account Manage/Token Expirery Timer/Login&Logout/Admin Role</h4>
      <div className='tag-container'>
        <span className='tech-tag'>Front End: React</span><span className='tech-tag'>Back End: Node.js</span>
        <span className='tech-tag'>Mail: SendGrid</span>
        <span className='tech-tag'>Token: jwt</span>
        <span className='tech-tag'>Database: Firebase</span>
        <span className='tech-tag'>Database: PostgreSQL</span>
        <span className='tech-tag'>Database: MySQL</span>
        <span className='tech-tag'>Database: MongoDB</span>
      </div>
      <div className='sample-contents-container-002'>
        <div className='table-container'>
          {!testAccountDetail?<div  onClick={()=>setTestAccountDetail(!testAccountDetail)}><u>Use test account to login?</u></div>:<div onClick={()=>setTestAccountDetail(!testAccountDetail)}><div className='result-data-container login-display-box'>
          <p><b>Normal User Test Account</b><br/>Email: normal@gmail.com<br/>Password: 1<br/>*only for firebase, password is 123456</p>
          <p><b>Admin User Test Account</b><br/>Email: admin@gmail.com<br/>Password: 1<br/>*only for firebase, password is 123456</p>
          </div>
          <div className='result-data-container login-display-box2'>
          <p>
            <b>Backend Check:</b><br />
            {connectCheckBackend ? <>{connectCheckBackend.msg}<br /></> : <>Loading back end data...<br /></>}
            {connectCheckPG ? <>{connectCheckPG.msg}<br /></> : <>Loading postgres database...<br /></>}
            {connectCheckSQL ? <>{connectCheckSQL.msg}</> : <>Loading mySQL database...<br /></>}
          </p>
          </div></div>}
        </div>
        <div className='sample-input-container'>
            <div className='login-tab mb-4'>
              {optionTabs.map((x, i) => (<button
                key={i}
                onClick={() => setSelectedTable(x.ttl)}
                className={selectedTable === x.ttl ? 'login-tab-item-active' : undefined}
                style={{ background: '#fff', padding: '0 4px', fontSize: '12px', color: 'black', position: 'relative' }}>{x.ttl}</button>
              ))}
            </div>
            <div className='login-controller-box'>
              {optionTabs.map((x, i) => x.ttl === selectedTable && <div key={i}>{x.table}</div>)}
            </div>
        </div>
      </div>
    </div>
  )
}
//component to display/update user typeA for normal user
//component to display/update user typeB for admin
//component to display/update users
// export default function BookLayout({title, children}) {
// function BookLayout({title, children}) {
//   return(
//     <div style={{margin: '8px', padding: '8px'}}>
//     <h2 style={{borderBottom: '1px solid black', fontSize:'16px'}}><b>◆{title}◆</b></h2>
//     <div style={{marginTop: '4px',display:'flex', flexWrap: 'wrap'}}>
//     {children}
//     </div>
//     </div>
//   )
// }

const SectionTitle = ({ title }) => {
  return (
    <h2 className='section-title'>{title}</h2>
  )
}
const FBLogin = ({ fbLoginData1 }) => {
  const [msg, setMsg] = useState('')
  const [errMsg, setErrMsg] = useState('')
  const [err, setErr] = useState('')
  const [data, setData] = useState({username:'', email: '', role:'', iatstring:'', expstring:'',iat:'', exp:'', usersdata:[{_id:'',firstname:'',lastname:'',email:'',pwd:'',role:''}]})
  const [justLogined, setJustLogined] = useState(false)
  const [isExpired, setIsExpired] = useState(true)
  const [loading, setLoading] = useState(false)
  const [page, setPage] = useState('Firebase Login')
  const [currentUser, setCurrentUser] = useState({})
  useEffect(() => {
    const remove = setTimeout(() => {
      setMsg('')
    }, 3000)
    return () => clearTimeout(remove)
  }, [])
  const onClick2CloseMsg = async (e) => {
    e.preventDefault()
    setMsg('')
  }
  const onClick2CloseErrMsg = async (e) => {
    e.preventDefault()
    setErrMsg('')
  }
  const onChangeInput = ({ currentTarget: input }) => { setData({ ...data, [input.name]: input.value }) }
   //one time passcode
   const onChange2Input = ({ currentTarget: input }) => { setData2({ ...data2, [input.name]: input.value }) }
   //set new pwd
   const onChange3Input = ({ currentTarget: input }) => { setData3({ ...data3, id: data2.id, [input.name]: input.value }) }
   //set pwd
  //  const [data2, setData2] = useState({
  //   id: '',
  //   token: ''
  //   })
     // set new pwd
  const [data2, setData2] = useState({
    pwd: '',
    pwd2: ''
  })
  const [data3, setData3] = useState({
    id: '',
    pwd: '',
    pwd2: ''
  })
  const onSubmit2Login = async (e) => {
    e.preventDefault()
    try {
      setLoading(true)
      await signInWithEmailAndPassword(auth, data.email, data.pwd)
      .then(async(x1) => {
        console.log('user credential', x1.user)
        console.log('user credential token', x1.user.accessToken)
        const unsub = onSnapshot(
        collection(db, 'account'),
        (x) => {
        let x4=[]
        x.docs.forEach((x2) => {
          x4.push({id:x2.id, ...x2.data()})
        })
        const x3 = x4.filter((x)=> x.email === x1.user.email)
        if(x3[0].role==='admin'){
          localStorage.setItem('fb_token', JSON.stringify({token:x1.user.accessToken, id:x1.user.uid, email:data.email, role:x3[0].role, iat:Date.now(),exp:Date.now()+process.env.REACT_APP_FIREBASE_LOGIN_EXPIRES_IN*60*1000}))
          fbLoginData1({
            id:x1.user.uid,
            username:x3[0].username,
            email:data.email,
            pwd:data.pwd,
            role:x3[0].role,
            token:x1.user.accessToken,
            msg:'You are successfully logined as admin user.',
            iatstring:new Date(Date.now()).toString(),
            expstring:new Date(Date.now()+process.env.REACT_APP_FIREBASE_LOGIN_EXPIRES_IN*60*1000).toString(),
            iat:Date.now(),
            exp: Date.now()+process.env.REACT_APP_FIREBASE_LOGIN_EXPIRES_IN*60*1000,
            justLogined:'true',
            usersdata:x4
          })
        }else if (x3[0].role === 'undefined'){
          return
        }else{
          localStorage.setItem('fb_token', JSON.stringify({token:x1.user.accessToken, id:x1.user.uid, email:data.email, iat:Date.now(),exp:Date.now()+process.env.REACT_APP_FIREBASE_LOGIN_EXPIRES_IN*60*1000}))
          fbLoginData1({
            id:x1.user.uid,
            username:x3[0].username,
            email:data.email,
            role:x3[0].role,
            pwd:data.pwd,
            token:x1.user.accessToken,
            iatstring:new Date(Date.now()).toString(),
            expstring:new Date(Date.now()+process.env.REACT_APP_FIREBASE_LOGIN_EXPIRES_IN*60*1000).toString(),
            iat:Date.now(),
            exp: Date.now()+process.env.REACT_APP_FIREBASE_LOGIN_EXPIRES_IN*60*1000,
            justLogined:'true',
            msg:'You are successfully logined as normal member.'
          })
        }
      },
      (error)=> {
        console.log(error)
      }
      )
      return ()=> {
        unsub()
        setJustLogined(true)
        const remove = setTimeout(() => {
            setMsg('')
        }, 3000)
        return () => clearTimeout(remove)
      }
      })
    } catch (err) {
      setErr('Invalid Email or Password.')
    }
    setTimeout(() => {
      setLoading(false)
    }, 3000)
  }
  const onClick2Login = async (e) => {
    e.preventDefault()
    setMsg('')
    setPage('Firebase Login')
  }
  const onClick2Register = async (e) => {
    e.preventDefault()
    console.log('Switched to Create Account')
    setMsg('')
    setData({ username:'', email: '', pwd: '' })
    setPage('Firebase Create Account')
  }
  const onClick2ResetPwd = async (e) => {
    e.preventDefault()
    console.log('Switched to Reset pwd')
    setMsg('')
    setIsExpired(true)
    setPage('Firebase Reset Password')
  }
  const onClick2LoginWithGoogle = async (e) => {
    e.preventDefault()
    try {
      await signInWithPopup(auth, googleProvider)
      console.log('logined with google account', auth.currentUser.providerData[0])
      fbLoginData1({
        email:auth.currentUser.providerData[0].email,
        pwd:'Please change from Google account setting',
        msg:'Logged in successfully'
      })
    } catch {
      setErr('Invalid Email or Password.')
    }
    setTimeout(() => {
      setLoading(false)
    }, 3000)
  }
  // Register
  const onSubmit2Register = async (e) => {
    e.preventDefault()
    if (data.pwd.length < 6) {
      setErr('Passsword needs to be more than 6 digits.')
    }
    try {
      setLoading(true)
      const x1 = await createUserWithEmailAndPassword(auth, data.email, data.pwd)
      await setDoc(doc(db, 'account', x1.user.uid), {...data, timeStamp:serverTimestamp(), role:'normal member'})
      //,displayName:data.username
      setPage('Firebase Login')
      setMsg('Your account is created❤️ Please login.')
    } catch (err) {
      setErr(`Email is already used in different account.${err}`)
    }
    setTimeout(() => {
      setLoading(false)
    }, 1000)
  }
  //reset pwd
  const onSubmit2Send = async (e) => {
    e.preventDefault()
    setErr('')
    try {
      sendPasswordResetEmail(auth, data.email)
      setMsg('Password reset link has been sent to your email😊 Please check your email box now.')
      setData({ email: '' })
    } catch (err) {
      setErr('This email does not have an account. Please Create your account from Create your account page.')
    }
  }
  const optionTabs = [
    {
      ttl: 'Firebase Login',
      table: <><div className='login-container'>
        <div className='notification-container'>
      {msg && <div className='notification flex flex-row justify-between'><div>{msg}</div><div><IoIosClose onClick={onClick2CloseMsg}/></div></div>}
      {errMsg && <div className='account-err-msg flex flex-row justify-between'><div>{errMsg}</div><div><IoIosClose onClick={onClick2CloseErrMsg}/></div></div>}
      </div>
        <div className='login-nav'>Firebase Login</div>
        <div className='login-input-container'>
          <form onSubmit={onSubmit2Login}>
            <div className='text-left'>
              <label>Email Address</label><br/>
              <input className='create-new-account-input' type='email' name='email'
              value={data.email}
              onChange={onChangeInput} />
            </div>
            <div className='text-left'>
              <label>Password</label><br/>
              <input className='create-new-account-input' type='text' name='pwd'
              value={data.pwd}
              onChange={onChangeInput} />
            </div>
            {err && <div className='create-new-account-err'>{err}</div>}
            <button type='submit' className='login-btn' disabled={!(data.email && data.pwd)}
            >Login</button>
          </form>
          <button type='button' className='flex login-btn google-login-btn' onClick={onClick2LoginWithGoogle}
          ><AiFillGoogleCircle /> Login with Google Account</button>
          <button onClick={onClick2Register} className='link-btn'>Create your account</button>
          <button onClick={onClick2ResetPwd} className='link-btn'>Reset password</button>
        </div>
      </div></>
    },
    {
      ttl: 'Firebase Create Account',
      table: <><div className='login-container'>
        <div className='notification-container'>
      {msg && <div className='notification flex flex-row justify-between'><div>{msg}</div><div><IoIosClose onClick={onClick2CloseMsg}/></div></div>}
      {errMsg && <div className='account-err-msg flex flex-row justify-between'><div>{errMsg}</div><div><IoIosClose onClick={onClick2CloseErrMsg}/></div></div>}
      </div>
        <div className='login-nav'>Firebase Register</div>
        <div className='login-input-container'>
          <form onSubmit={onSubmit2Register}>
            <div className='text-left'>
              <label>Username</label><br/>
              <input className='create-new-account-input' type='text' name='username' value={data.username} onChange={onChangeInput} />
            </div>
            <div className='text-left'>
              <label>Email Address</label><br/>
              <input className='create-new-account-input' type='email' name='email' value={data.email} onChange={onChangeInput} />
            </div>
            <div className='text-left'>
              <label>Set Password</label><br/>
              <input className='create-new-account-input' type='text' name='pwd' value={data.pwd} onChange={onChangeInput} />
            </div>
            {err && <div className='create-new-account-err'>{err}</div>}
            <button
              className='register-btn'
              type='submit'
              disabled={!(data.username && data.email && data.pwd) || loading}
            >Register</button>
          </form>
          <button onClick={onClick2Login} className='link-btn'>Already have account? Back to Login >></button>
        </div>
      </div></>
    },
    {
      ttl: 'Firebase Reset Password',
      table: <><div className='login-container'>
        <div className='notification-container'>
      {msg && <div className='notification flex flex-row justify-between'><div>{msg}</div><div><IoIosClose onClick={onClick2CloseMsg}/></div></div>}
      {errMsg && <div className='account-err-msg flex flex-row justify-between'><div>{errMsg}</div><div><IoIosClose onClick={onClick2CloseErrMsg}/></div></div>}
      </div>
        <div className='login-nav'>Firebase Reset Password</div>
        <div className='login-input-container'>
          <form onSubmit={onSubmit2Send}>
            <div className='text-left'>
              <label>Account Email</label><br/>
              <input className='create-new-account-input' type='email' name='email' value={data.email} onChange={onChange2Input} />
            </div>
            {err && <div className='account-err'>{err}</div>}
            <button className='login-btn' disabled={!(data.email)}>Send</button></form>
          {msg && <div className='account-sub-msg'>{msg}</div>}
          <button onClick={onClick2Login} className='link-btn'> Go Back</button>
          <button onClick={onClick2Register} className='link-btn'>Create your account</button>
        </div>
      </div></>
    },
    {
      ttl: 'Firebase Set Password Again',
      table: <><div className='login-container'>
        <div className='notification-container'>
      {msg && <div className='notification flex flex-row justify-between'><div>{msg}</div><div><IoIosClose onClick={onClick2CloseMsg}/></div></div>}
      {errMsg && <div className='account-err-msg flex flex-row justify-between'><div>{errMsg}</div><div><IoIosClose onClick={onClick2CloseErrMsg}/></div></div>}
      </div>
        <div className='login-nav'>Firebase Set Password</div>
        <div className='login-input-container'>
          <form
          //onSubmit={onSubmit2LoginB}
          >
            <div>
              <label>Password</label>
              <input className='create-new-account-input' type='text' name='pwd' value={data2.pwd} onChange={onChange2Input} />
            </div>
            <div>
              <label>Confirm Password</label>
              <input className='create-new-account-input' type='text' name='pwd2' value={data.pwd2} onChange={onChange2Input} />
            </div>
            {(data2.pwd2.length != 0) && data2.pwd != data2.pwd2 && <div className='create-new-account-err'>Please input same password</div>}
            {err && <div className='create-new-account-err'>{err}</div>}
            <button className='login-btn' disabled={!(data2.pwd && data2.pwd2)}>Apply</button></form>
          <button className='link-btn'> Go Back</button>
          <button className='link-btn'>Create your account</button>
        </div>
      </div></>
    }
  ]
  return (
    <>
      {page === 'Firebase Login' ? <>{optionTabs[0].table}</> : <>{page === 'Firebase Create Account' ? <>{optionTabs[1].table}</> : <>{page === 'Firebase Reset Password' ? <>{optionTabs[2].table}</> : <>{optionTabs[3].table}</>}</>}</>}
    </>
  )
}
const FB = (props) => {
  const [msg, setMsg] = useState('')
  const [errMsg, setErrMsg] = useState('')
  const [err, setErr] = useState('')
  const [data, setData] = useState({username:'', email: '', pwd:'', role:'', iatstring:'', expstring:'',iat:'', exp:'', usersdata:[{_id:'',username:'',email:'',pwd:'',role:''}]})
  const [data2, setData2] = useState({username:'', email: '', pwd:'',role:''})
  const [currentUser, setCurrentUser] = useState({})
  const [justLogined, setJustLogined] = useState(false)
  const [tokenChecked, setTokenChecked]=useState(false)
  const [timer, setTimer] = useState('')
  const [isExpired, setIsExpired] = useState(true)
  const [updateUserId, setUpdateUserId] = useState(null)
  const [edit, setEdit] = useState(false)
  const [edit2, setEdit2] = useState(false)
  const fbToken = localStorage.getItem('fb_token')
  //User data
  const Ref = useRef(null)
  useEffect(() => {
    // when user clicked login
    if (props.fbLoginData1 !== null) {
      const handleSubmit = async (e) => {
        if(justLogined === false){
          onAuthStateChanged(auth, user => setCurrentUser(user))
          setMsg(props.fbLoginData1.msg)
          console.log('props.fbLoginData1',props.fbLoginData1)
          setData(props.fbLoginData1)
          await setData(props.fbLoginData1)
          setJustLogined(true)
        }
        function expiredMsg() {
          setMsg('Token expired. Please login again')
          setTimeout(() => {
            signOut(auth)
            localStorage.removeItem('fb_token')
            indexedDB.deleteDatabase('firebase-heartbeat-database')
            indexedDB.deleteDatabase('firebaseLocalStorageDb')
            }, 3500)
        }
        setTimeout(expiredMsg, Number(props.fbLoginData1.exp - props.fbLoginData1.iat))
        Ref.current = setInterval(() => {
        let x = new Date()
        const y = props.fbLoginData1.exp - Date.parse(new Date())
        if (y >= 0) {
          setTimer((Math.floor((y / 1000 / 60 / 60)) > 1 ? Math.floor((y / 1000 / 60 / 60)) + 'days ' : Math.floor((y / 1000 / 60 / 60)) + 'day ') + (Math.floor((y / 1000 / 60 / 60) % 24) > 9 ? Math.floor((y / 1000 / 60 / 60) % 24) : '0' + Math.floor((y / 1000 / 60 / 60) % 24)) +':' + (Math.floor((y / 1000 / 60) % 60) > 9? Math.floor((y / 1000 / 60) % 60): '0' + Math.floor((y / 1000 / 60) % 60)) +':' +(Math.floor((y / 1000) % 60) > 9 ? Math.floor((y / 1000) % 60) : '0' + Math.floor((y / 1000) % 60)))
        }
        }, 1000)
      }
      setTimeout(handleSubmit, 5)
    }
    //when user refleshed page
    if(props.fbLoginData1 === null && tokenChecked === false){
      // if(JSON.parse(fbToken).role){
      //   setData({role:JSON.parse(fbToken).role, usersdata:[{}]})
      // }
      const handleSubmit = async (e) => {
      try {
        // onAuthStateChanged(auth, user => setCurrentUser(user), console.log('when user refleshed page currentuser', user))
        const res = await axios.post(`${APIURL}/api/fb/tokencheck`, {headers:{authtoken:JSON.parse(fbToken).token}, body:{id:JSON.parse(fbToken).id}})
        setTokenChecked(true)
        const unsub = onSnapshot(
          collection(db, 'account'),
          (x) => {
          let x4=[]
          x.docs.forEach((x2) => {
          x4.push({id:x2.id, ...x2.data()})
          })
          console.log('docs 1', x4)
          const x3 = x4.filter((x)=> x.email === JSON.parse(fbToken).email)
          console.log('docs 2', x3)
          setData({
          id:JSON.parse(fbToken).id,
          username:x3[0].username,
          email:JSON.parse(fbToken).email,
          pwd:data.pwd,
          role:x3[0].role,
          token:JSON.parse(fbToken).token,
          msg:'You are successfully logined as admin user.',
          iatstring:new Date(JSON.parse(fbToken).iat).toString(),
          expstring:new Date(JSON.parse(fbToken).exp).toString(),
          iat:JSON.parse(fbToken).iat,
          exp: JSON.parse(fbToken).exp,
          justLogined:'true',
          usersdata:x3[0].role==='admin'? x4: ''
          })
        },
        (error)=> {console.log(error)}
        )
        function expiredMsg() {
          setMsg('Token expired. Please login again')
          setTimeout(() => {
            signOut(auth)
            localStorage.removeItem('fb_token')
            indexedDB.deleteDatabase('firebase-heartbeat-database')
            indexedDB.deleteDatabase('firebaseLocalStorageDb')
            }, 3500)
        }
        setTimeout(expiredMsg, Number(JSON.parse(fbToken).exp - JSON.parse(fbToken).iat))
        Ref.current = setInterval(() => {
        let x = new Date()
        const y = JSON.parse(fbToken).exp - Date.parse(new Date())
        if (y >= 0) {
          setTimer((Math.floor((y / 1000 / 60 / 60)) > 1 ? Math.floor((y / 1000 / 60 / 60)) + 'days ' : Math.floor((y / 1000 / 60 / 60)) + 'day ') + (Math.floor((y / 1000 / 60 / 60) % 24) > 9 ? Math.floor((y / 1000 / 60 / 60) % 24) : '0' + Math.floor((y / 1000 / 60 / 60) % 24)) +':' + (Math.floor((y / 1000 / 60) % 60) > 9? Math.floor((y / 1000 / 60) % 60): '0' + Math.floor((y / 1000 / 60) % 60)) +':' +(Math.floor((y / 1000) % 60) > 9 ? Math.floor((y / 1000) % 60) : '0' + Math.floor((y / 1000) % 60)))
        }
        }, 1000)
        return ()=> {
          unsub()
          setJustLogined(true)
        }
        } catch (err) {
          console.error('Error')
        }
      }
      setTimeout(handleSubmit, 4)
  }
  }, [data])
  const onClick2CloseMsg = async (e) => {
    e.preventDefault()
    setMsg('')
  }
  const onClick2CloseErrMsg = async (e) => {
      e.preventDefault()
      setErrMsg('')
  }
  const onClick2Logout = async (e) => {
    e.preventDefault()
    localStorage.removeItem('fb_token')
    await signOut(auth)
    setMsg('See you soon!')
    setTimeout(() => {
      window.location.reload()
    }, 2500)
  }
  //normal member - user data
  const onClick2MyAccountEdit = async(x) => {
    await setEdit(!edit)
    setData2(x)
  }
  const onSubmit2MyAccountUpdate = async (e) => {
    e.preventDefault()
    try{
      await updateEmail(auth.currentUser, data2.email)
      await updatePassword(auth.currentUser, data2.pwd)
      await setDoc(doc(db, 'account', data2.id), {...data2})
      setUpdateUserId(null)
      const unsub = onSnapshot(
      collection(db, 'account'),
        (snapShot) => {
        let x4=[]
        snapShot.docs.forEach((x2) => {
          x4.push({id:x2.id, ...x2.data()})
        })
        const x3 = x4.filter((x)=> x.id === data2.id)
        setData({...data,username:x3[0].username, email:data2.email, pwd:data2.pwd, role:x3[0].role})
        setMsg('Account updated.')
        setEdit(false)
      },
      (error)=> {
        console.log(error)
      }
      )
      return ()=> {
        unsub()
        setMsg('Account updated.')
        const remove = setTimeout(() => {
            setMsg('')
        }, 3000)
        return () => clearTimeout(remove)
      }
  } catch(err){
      setErrMsg(`System Error: ${err}`)
  }
  }
  const onClick2MyAccountDelete = async (e) => {
    e.preventDefault()
    try {
      deleteUser(auth.currentUser)
      deleteDoc(doc(db, 'account', data.id))
      setMsg('Your account is deleted successfully.')
      signOut(auth)
      window.location.reload()
    } catch (err) {
      setErr(`System Error: ${err}`)
    }
  }
  // admin - user data
  const onClick2UserDataCancel = async (e) => {
    e.preventDefault()
    setEdit2(false)
  }
  const onClick2UserEdit = async(x) => {
      await setEdit2(true)
      setData2({ id:x.id, username:x.username, email:x.email, pwd:x.pwd, role:x.role}) 
  }
  const onClick2UserUpdate = async (e) => {
    e.preventDefault()
    try{
      await updateEmail(auth.currentUser, data2.email)
      await updatePassword(auth.currentUser, data2.pwd)
      await setDoc(doc(db, 'account', data2.id), {...data2, timeStamp:serverTimestamp()})
      setUpdateUserId(null)
      const unsub = onSnapshot(
      collection(db, 'account'),
        (snapShot) => {
        let x4=[]
        snapShot.docs.forEach((x2) => {
          x4.push({id:x2.id, ...x2.data()})
        })
        const x3 = x4.filter((x)=> x.id === data2.id)
        setData({...data,username:x3[0].username, email:data2.email, pwd:data2.pwd, role:x3[0].role, usersdata:x4})
        setMsg('Account updated.')
        setUpdateUserId(null)
        setEdit2(false)
      },
      (error)=> {
        console.log(error)
      }
      )
      return ()=> {
        unsub()
        const remove = setTimeout(() => {
            setMsg('')
        }, 3000)
        return () => clearTimeout(remove)
      }
  } catch(err){
      setErrMsg(`System Error: ${err}`)
  }
  }
  const onClick2UserDelete = async (e) => {
    e.preventDefault()
    console.log('remove (', data, ') account')
    try {
      const { data: res } = await axios.post(`${APIURL}/api/mongo/userdata/delete`, data)
      console.log('account deleted: ', res.msg)
      localStorage.removeItem('fb_token')
      setMsg(res.msg)
      setTimeout(() => {
        window.location.reload()
      }, 2500)
    } catch (err) {
      if (err.response && err.response.status >= 400 && err.response.status <= 500) {
        setErr(err.response.data.msg)
      }
    }
  }
  // admin - users data
  const onClick2UsersDelete = async(e, x, y) => {
    e.preventDefault()
    try {
      //(1) remove firebase data
      await deleteDoc(doc(db, 'account', x))
      //(2) remove auth data
      const res = await axios.post(`${APIURL}/api/fb/usersdata/delete`, {id:x, username:y})
      if(updateUserId === data2.id){
        setUpdateUserId()
        setMsg('Your account is deleted successfully.')
        signOut(auth)
        window.location.reload()
      } else {
        let w=[]
        const a = data.usersdata
        const b = a.filter(z=>z.id !== x)
        w.push(...b)
        setData({...data, usersdata:w})
        setUpdateUserId()
        setMsg(res.data.msg)
      }
    } catch (err) {
      setErr(`System Error: ${err}`)
    }
  }
  const onClick2UsersEdit = async (x) => {
      await setUpdateUserId(x.id)
      setData2({ id:x.id, username:x.username, email:x.email, pwd:x.pwd, role:x.role })
  }
  const onClick2UsersUpdate = async (e) => {
    e.preventDefault()
    try{ 
    //(1) update firebase data
    await setDoc(doc(db, 'account', data2.id), {...data2})
    //(2) update auth data
    const res = await axios.post(`${APIURL}/api/fb/usersdata/update`, 
    {headers:{authtoken:data.token},body:{data1:data, data2:data2}})
    if(updateUserId === data2.id){
      let x=[]
      x.push(...data.usersdata)
      x[x.findIndex((x)=> x.id === updateUserId)]=data2
      setData({...data, username:data2.username, email:data2.email, pwd:data2.pwd, role:data2.role, usersdata:x })
    } else {
      let x=[]
      x.push(...data.usersdata)
      x[x.findIndex((x)=> x.id === updateUserId)]=data2
      setData({...data, usersdata:x})
    }
      setMsg(res.data.msg)
      setUpdateUserId()
    } catch(err){
        if (err.response && err.response.status >= 400 && err.response.status <= 500) {
            setErrMsg(err.response.msg)
        }
    }
  }
  const onClick2UsersCancel = async (e) => {
      e.preventDefault()
      setUpdateUserId('')
      setData2({ lastname:'', firstname:'', email: '', pwd: '', role:'' })
  }
  const onChange2Input = ({ currentTarget: input }) => { setData2({ ...data2, [input.name]: input.value }) }
  return (
    <>
      <div className='login-container'>
        <div className='notification-container'>
        {msg && <div className='notification flex flex-row justify-between'><div>{msg}</div><div><IoIosClose onClick={onClick2CloseMsg}/></div></div>}
        {errMsg && <div className='account-err-msg flex flex-row justify-between'><div>{errMsg}</div><div><IoIosClose onClick={onClick2CloseErrMsg}/></div></div>}
        </div>
        {data.role === 'admin'?
        <div>
        <div className='login-nav flex-row'>
        <div>Firebase Main <span>Admin</span></div>
        <div>
          <button
            className='logout-btn flex'
            onClick={onClick2Logout}
          ><FiLogOut />Logout</button>
          <div className='logout-container'>
           • This Login Token is available for <br/>
           Issued date:{new Date(data.iatstring).getUTCMonth() + 1}.{new Date(data.iatstring).getUTCDate()}.{new Date(data.iatstring).getUTCFullYear()} {new Date(data.iatstring).toLocaleTimeString()}<br/>
           Expire date: {new Date(data.expstring).getUTCMonth() + 1}.{new Date(data.expstring).getUTCDate()}.{new Date(data.expstring).getUTCFullYear()} {new Date(data.expstring).toLocaleTimeString()}<br/>
          • You have {timer} left
          </div>
          </div>
        </div>
        <h4>Member Accounts</h4>
        <div className='login-admin-container'>
          <div className='ttl flex justify-items-start'>
            <div></div>
            <div>Account ID</div>
            <div>Username</div>
            <div>Email</div>
            <div>Pwd</div>
            <div>Role</div>
            <div></div>
          </div>
          <>{(data.usersdata).map((x, key) =>
            <>{updateUserId !== x.id?
            <div className='item flex flex-row justify-items-start'>
            <div>{key+1}</div>
            <div>{x.id}</div>
            <div>{x.username}</div>
            <div>{x.email}</div>
            <div>{x.pwd}</div>
            <div>{x.role}</div>
          <div className='flex btn-pc'><button onClick={()=> onClick2UsersEdit(x)} className='edit-btn'>Edit</button>
          <button onClick={(e)=> onClick2UsersDelete(e,x.id,x.username)} className='delete2-btn'>Delete</button></div>
          <div className='flex btn-sp'>
          <div className='edit2-btn' style={{maskImage: `url("data:image/svg+xml,${svgStringMdEdit}")`}}>
          <div className='edit2-btn-bg' onClick={()=> onClick2UsersEdit(x)}></div></div>
          <div className='delete3-btn' style={{maskImage: `url("data:image/svg+xml,${svgStringIoIosClose}")`}}>
          <div className='delete3-btn-bg' onClick={(e)=> onClick2UsersDelete(e,x.id,x.username)}></div></div></div>
          </div> : <div className='item-edit flex flex-row justify-items-start text-left'>
          <div>{key+1}</div>
          <div>{x.id}</div>
          <div><input className='inputA' type='text' name='username' defaultValue={x.username} onChange={onChange2Input} />
          </div>
            <div><input className='inputA' type='email' name='email' defaultValue={x.email} onChange={onChange2Input} /></div>
            <div><input className='inputA' type='text' name='pwd' defaultValue={x.pwd} onChange={onChange2Input} /></div>
            <div><select name="role" defaultValue={x.role} onChange={onChange2Input}>
            <option hidden value="default">{x.role}</option>
            <option value="admin">Admin</option>
            <option value="normal member">Normal Member</option>
            <option value="premiere member">Premiere Member</option>
            </select></div>
            {err && <div className='create-new-account-err'>{err}</div>}
            <div className='flex'>
            <button onClick={onClick2UsersCancel} type='button'className='cancel-btn'>Cancel</button>
            <button onClick={onClick2UsersUpdate} type='button' className='update2-btn'
            disabled={!(data2.email && data2.pwd && data2.username)}>Update</button></div>
            </div>
            }</>
          )}</>
          <br/><br/>
          <h4>Your Account</h4>
          <div className='login-admin-container-user'>
            <div className='ttl flex justify-items-start'>
            <div></div>
            <div>Account ID</div>
            <div>Username</div>
            <div>Email</div>
            <div>Pwd</div>
            <div>Role</div>
            <div></div>
            </div>
          {edit2 !== false ?
            <div className='item-edit flex justify-items-start text-left'>
            <div></div>
            <div className='text-left'>{data2.id}</div>
            <div><input className='inputA' type='text' name='username' defaultValue={data2.username} onChange={onChange2Input} /></div>
            <div><input className='inputA' type='email' name='email' defaultValue={data2.email} onChange={onChange2Input} /></div>
            <div><input className='inputA' type='text' name='pwd' defaultValue={data2.pwd} onChange={onChange2Input} /></div>
            <div><select name="role" defaultValue={data2.role} onChange={onChange2Input}>
            <option value="admin">Admin</option>
            <option value="normal member">Normal Member</option>
            <option value="premiere member">Premiere Member</option>
            </select></div>
            <div className='flex'><button
            className='cancel-btn' onClick={onClick2UserDataCancel}
            >Cancel</button><button
            onClick={onClick2UserUpdate}
              className='text-left update2-btn'
              type='button'
              disabled={!(data2.username&&data2.email&&data2.pwd)}
            >Update</button></div>
            {/* {err && <div className='create-new-account-err'>{err}</div>} */}
          </div> : <div className='item flex flex-row justify-items-start'><div></div><div>{data.id}</div><div>{data.username}</div><div>{data.email}</div><div>{data.pwd}</div><div>{data.role}</div><div className='flex btn-pc'><button className='edit-btn' onClick={()=> onClick2UserEdit(data)}
          >Edit</button><button className='delete2-btn text-xs' onClick={onClick2UserDelete}>Delete Account</button></div><div className='flex btn-sp'>
          <div className='edit2-btn' style={{maskImage: `url("data:image/svg+xml,${svgStringMdEdit}")`}}>
          <div className='edit2-btn-bg' onClick={()=> onClick2UserEdit(data)}></div></div>
          <div className='delete3-btn' style={{maskImage: `url("data:image/svg+xml,${svgStringIoIosClose}")`}}>
          <div className='delete3-btn-bg' onClick={onClick2UserDelete}></div></div></div></div>}
          </div>
          <br />
        </div>
        </div>:
        <>{!data.role?'loading..':
        <><div className='login-nav'>
          <div>Firebase Main</div>
          <div className='flex'>
          <div className='logout-container'>
           • This Login Token is available for <br/> {new Date(data.iatstring).getUTCMonth() + 1}.{new Date(data.iatstring).getUTCDate()}.{new Date(data.iatstring).getUTCFullYear()} {new Date(data.iatstring).toLocaleTimeString()} - {new Date(data.expstring).getUTCMonth() + 1}.{new Date(data.expstring).getUTCDate()}.{new Date(data.expstring).getUTCFullYear()} {new Date(data.expstring).toLocaleTimeString()}<br/>
          • You have {timer} left
          </div>
        <div>
          <button
            className='logout-btn flex'
            onClick={onClick2Logout}
          ><FiLogOut />Logout</button>
          </div>
        </div>
        </div>
        <div className='login-contents-container'
        >
          <div className='menu-item'><b>Change account details</b>
            {data.pwd !== 'Please change from Google account setting' ? <>{edit ? <button className='cancel-btn' onClick={() => setEdit(!edit)}>Cancel</button>:<button className='cancel-btn'
            onClick={()=> onClick2MyAccountEdit(data)}>Edit</button>}</> : ''}
          </div>
          {edit ? <form onSubmit={onSubmit2MyAccountUpdate}>
            <b>Account ID:{data.id}</b>
            <div>
              <label>Username</label><br/>
              <input className='create-new-account-input' type='text' name='username' defaultValue={data2.username} onChange={onChange2Input} />
            </div>
            <div>
              <label>Email Address</label><br/>
              <input className='create-new-account-input' type='email' name='email' defaultValue={data2.email} onChange={onChange2Input} />
            </div>
            <div>
              <label>Password</label><br/>
              <input className='create-new-account-input' type='text' name='pwd' value={data2.pwd} onChange={onChange2Input} />
            </div>
            Role:{data2.role}<br/>
            <button
              className='update-btn'
              type='submit'
              disabled={!(data2.username&&data2.email&&data2.pwd)}
            >Update</button>
            {err && <div className='create-new-account-err'>{err}</div>}
          </form> : <>
            Account ID: {data.id}<br />
            Username: {data.username}<br />
            Email Address: {data.email}<br />
            Password: {data.pwd}<br />
            Role:{data.role}</>}
          <br />
          {data.pwd !== 'Please change from Google account setting' ?
            <div className='menu-item'>
              <b>Delete Account</b><br />
              <button
                className='delete-btn'
                onClick={onClick2MyAccountDelete}
              >Delete</button>
            </div> : ''}
        </div></>}
        </>}
      </div>
    </>
  )
}
const PGLogin = ({ pgLoginData1 }) => {
  const [msg, setMsg] = useState('')
  const [errMsg, setErrMsg] = useState('')
  const [err, setErr] = useState('')
  const [data, setData] = useState({username:'', email: '', role:'', iatstring:'', expstring:'',iat:'', exp:'', usersdata:[{_id:'',firstname:'',lastname:'',email:'',pwd:'',role:''}]})
  const [encryption, setEncryption] = useState({ id: '', encryption: '', pwd: '' })
  const [justLogined, setJustLogined] = useState(false)
  const [isExpired, setIsExpired] = useState(true)
  const [page, setPage] = useState('PostgreSQL Login')
  const onChangeInput = ({ currentTarget: input }) => { setData({ ...data, [input.name]: input.value }) }
  //one time passcode
  const onChange2Input = ({ currentTarget: input }) => { setData2({ ...data2, [input.name]: input.value }) }
  //set new pwd
  const onChange3Input = ({ currentTarget: input }) => { setData3({ ...data3, id: data2.id, [input.name]: input.value }) }
  const onClick2CloseMsg = async (e) => {
    e.preventDefault()
    setMsg('')
  }
  const onClick2CloseErrMsg = async (e) => {
    e.preventDefault()
    setErrMsg('')
  }
  const onSubmit2Login = async (e) => {
    e.preventDefault()
    try {
      //get tokenInfo, user, users
      const { data: res } = await axios.post(`${APIURL}/api/pg/login`, data)
      localStorage.setItem('pg_token', res.tokenInfo.token)
      pgLoginData1({
        id:res.user.id,
        username:res.user.username,
        email:data.email,
        pwd:data.pwd,
        role:res.user.role,
        msg:res.msg,
        iatstring:new Date(res.tokenInfo.iat*1000).toString(),
        expstring:new Date(res.tokenInfo.exp*1000).toString(),
        iat:res.tokenInfo.iat,
        exp:res.tokenInfo.exp,
        justLogined:'true',
        usersdata:res.users
      })
      setJustLogined(true)
      setMsg(res.msg)
      const remove = setTimeout(() => {
        setMsg('')
      }, 3000)
      return () => clearTimeout(remove)
    } catch (err) {
      if (err.response && err.response.status >= 400 && err.response.status <= 500) {
        setErrMsg(err.response.data.msg)
      }
    }
    setErr('')
  }
  const onClick2Login = async (e) => {
    e.preventDefault()
    setData({ email: '', pwd: '' })
    setErr('')
    setPage('PostgreSQL Login')
  }
  const onClick2Register = async (e) => {
    e.preventDefault()
    setData({ email: '', pwd: '' })
    setErr('')
    setPage('PostgreSQL Create Account')
  }
  const onClick2ResetPwd = async (e) => {
    setMsg('')
    e.preventDefault()
    setPage('PostgreSQL Reset Password')
  }
  //Register
  const onSubmit2Register = async(e) => {
    e.preventDefault()
    console.log('onSubmit2Register clicked 1', data)
    try {
     const { data: res } = await axios.post(`${APIURL}/api/pg/register`, data)
    console.log('msg',res)
        setMsg(res.msg)
        setData({ email: '', pwd: '' })
        setErr('')
        setPage('PostgreSQL Login')

    } catch (err) {
      if (err.response && err.response.status >= 400 && err.response.status <= 500) {
        setErr(err.response.data.msg)
      }
    }
  }
  //reset pwd
  const onSubmit2Send = async (e) => {
    e.preventDefault()
    console.log('send mail')
    setErr('')
    const fetchData = async () => {
      //email check
      await axios.post(`${APIURL}/api/pg/password-reset-mail`, data)
        .then(async res => {
          if (res.data.data.id)
            if (!err) {
              //issue token & send mail
              setData2({ id: res.data.data.id })
              await axios.post(`${APIURL}/api/pg/password-reset-mail2`, {
                id: res.data.data.id,
                email: data.email
              })
                .then(async res => {
                  setMsg(res.data.msg)
                }).catch(function (err) {
                  setErr('System Error. Please try it again later.')
                })
            }
        }).catch(function (err) {
          setErr(err.response.data.err_msg)
        })
    }
    fetchData()
  }
  //set pwd
  const [data2, setData2] = useState({
    id: '',
    token: ''
  })
  const [err2, setErr2] = useState('')
  const [data3, setData3] = useState({
    id: '',
    pwd: '',
    pwd2: ''
  })
  const onSubmit2OneTimePasscode = async (e) => {
    e.preventDefault()
    try {
      const payload = JSON.parse(window.atob(data2.token.split('.')[1]))
      // console.log('payload', payload)
      // console.log('Exp', new Date(payload.exp * 1000))
      const isExpired = Date.now() >= payload.exp * 1000
      console.log('isExpired', isExpired)
      // console.log('Date.now()', Date.now())
      // console.log('payload.exp*1000', payload.exp * 1000)
      // console.log('Date.now()-payload.exp*1000', Date.now() - payload.exp * 1000)
      if (!isExpired) {
        setErr2('')
        console.log('account id', data2.id)
        setIsExpired(!(!isExpired))
      } else {
        setErr2('Token Expired')
        console.log('Token Expired')
      }
    } catch (err) {
      console.log('System Error', err)
    }
  }
  const onSubmit2NewPwd = async (e) => {
    e.preventDefault()
    console.log('data3', data3)
    try {
      const url = `${APIURL}/api/pg/set-password-again`
      const { data: res } = await axios.post(url, data3)
        .then(async res => {
          console.log('response', res.data)
          setMsg(res.data.msg)
          setPage('PostgreSQL Login')
          const remove = setTimeout(() => {
            setMsg('')
          }, 2500)
          return () => clearTimeout(remove)
        })
    } catch (err) {
      console.log('System Error', err)
    }
  }
  const optionTabs = [
    {
      ttl: 'PostgreSQL Login',
      table: <><div className='login-container'>
        <div className='notification-container'>
          {msg && <div className='notification flex flex-row justify-between'><div>{msg}</div><div><IoIosClose onClick={onClick2CloseMsg}/></div></div>}
          {errMsg && <div className='account-err-msg flex flex-row justify-between'><div>{errMsg}</div><div><IoIosClose onClick={onClick2CloseErrMsg}/></div></div>}
        </div>
        <div className='login-nav'>PorstgreSQL Login</div>
        <div className='login-input-container'>
          <form onSubmit={onSubmit2Login}>
            {Object.values(encryption)}
            <div className='text-left'>
              <label>Account Email</label><br/>
              <input className='create-new-account-input' type='email' name='email' value={data.email} onChange={onChangeInput} />
            </div>
            <div className='text-left'>
              <label>Password</label><br/>
              <input className='create-new-account-input' type='text' name='pwd' value={data.pwd} onChange={onChangeInput} />
            </div>
            {err && <div className='create-new-account-err'>{err}</div>}
            <button type='submit' className='login-btn' disabled={!(data.email && data.pwd)}
            >Login</button>
          </form>
          <button onClick={onClick2Register} className='link-btn'>Create your account</button>
          <button onClick={onClick2ResetPwd} className='link-btn'>Reset password</button>
        </div>
      </div></>
    },
    {
      ttl: 'PostgreSQL Create Account',
      table: <><div className='login-container'>
        <div className='notification-container'>
          {msg && <div className='notification flex flex-row justify-between'><div>{msg}</div><div><IoIosClose onClick={onClick2CloseMsg}/></div></div>}
          {errMsg && <div className='account-err-msg flex flex-row justify-between'><div>{errMsg}</div><div><IoIosClose onClick={onClick2CloseErrMsg}/></div></div>}
        </div>
        <div className='login-nav'>PorstgreSQL Register</div>
        <div className='login-input-container'>
          <form onSubmit={onSubmit2Register}>
            <div className='text-left'>
              <label>Username</label><br/>
              <input className='create-new-account-input' type='text' name='username' value={data.username} onChange={onChangeInput} />
            </div>
            <div className='text-left'>
              <label>Email Address</label><br />
              <input className='create-new-account-input' type='email' name='email' value={data.email} onChange={onChangeInput} />
            </div>
            <div className='text-left'>
              <label>Set Password</label><br />
              <input className='create-new-account-input' type='text' name='pwd' value={data.pwd} onChange={onChangeInput} />
            </div>
            {err && <div className='create-new-account-err'>{err}</div>}
            <button
              className='register-btn'
              type='submit'
              disabled={!(data.username && data.email && data.pwd)}
            >Register</button>
          </form>
          <button onClick={onClick2Login} className='link-btn'>Already have account? Back to Login </button>
        </div>
      </div></>
    },
    {
      ttl: 'PostgreSQL Reset Password',
      table: <><div className='login-container'>
        <div className='notification-container'>
          {msg && <div className='notification flex flex-row justify-between'><div>{msg}</div><div><IoIosClose onClick={onClick2CloseMsg}/></div></div>}
          {errMsg && <div className='account-err-msg flex flex-row justify-between'><div>{errMsg}</div><div><IoIosClose onClick={onClick2CloseErrMsg}/></div></div>}
        </div>
        <div className='login-nav'>PorstgreSQL Reset Password</div>
        {isExpired ? <>
          <div className='login-input-container'>
            <form onSubmit={onSubmit2Send}>
              <div className='text-left'>
                <label>Account Email</label><br/>
                <input className='create-new-account-input' type='email' name='email' value={data.email} onChange={onChangeInput} />
              </div>
              {err && <div className='account-err'>{err}</div>}
              <button className='login-btn' disabled={!(data.email)}>Send</button></form>
            {msg && <div className='account-sub-msg'>{msg}</div>}
            <br />
            {/* </div>
      <div className='login-input-container'> */}
            {/* Token Expired. Please send reset password mail again. */}
            <form onSubmit={onSubmit2OneTimePasscode}>
              <div>
                <label>Account ID</label>
                <input className='create-new-account-input' type='name' name='id' value={data2.id} onChange={onChange2Input} />
              </div>
              <div>
                <label>Onetime Passcode</label>
                <input className='create-new-account-input' type='text' name='token' value={data2.token} onChange={onChange2Input} />
              </div>
              {err && <div className='create-new-account-err'>{err}</div>}
              <button className='login-btn'
                disabled={!(data2.id && data2.token)}
              >Reset Password</button></form>
            <button onClick={onClick2ResetPwd} className='link-btn'>Go Back</button>
            <button onClick={onClick2Register} className='link-btn'>Create your account</button>
          </div>
        </> :
          <div className='login-input-container'>
            <form onSubmit={onSubmit2NewPwd}>
              <div className='login-input-ttl'>Please set your new password</div>
              <div className='login-input-txt'>
                <label>Account Id</label>
                {data2.id}
              </div>
              <div>
                <label>Password</label>
                <input className='create-new-account-input' type='text' name='pwd' value={data3.pwd} onChange={onChange3Input} />
              </div>
              <div>
                <label>Confirm Password</label>
                <input className='create-new-account-input' type='text' name='pwd2' value={data3.pwd2} onChange={onChange3Input} />
              </div>
              {(data3.pwd2.length !== 0) && data3.pwd !== data3.pwd2 && <div className='create-new-account-err'>Please input same password</div>}
              {err && <div className='create-new-account-err'>{err}</div>}
              <button className='login-btn' disabled={!(data3.pwd && data3.pwd2)}>Send</button></form>
            <button className='link-btn' onClick={onClick2ResetPwd}>Go Back</button>
            <button className='link-btn' onClick={onClick2Register}>Create your account</button>
          </div>}
      </div>
      </>
    }
  ]
  return (
    <>
      {page === 'PostgreSQL Login' ? <>{optionTabs[0].table}</> : <>{page === 'PostgreSQL Create Account' ? <>{optionTabs[1].table}</> : <>{page === 'PostgreSQL Reset Password' ? <>{optionTabs[2].table}</> : <>{optionTabs[3].table}</>}</>}</>}
    </>
  )
}
const PG = (props) => {
  const [msg, setMsg] = useState('')
  const [errMsg, setErrMsg] = useState('')
  const [err, setErr] = useState('')
  const [data, setData] = useState({username:'', email: '', pwd:'', role:'', iatstring:'', expstring:'',iat:'', exp:'', usersdata:[{id:'',username:'',email:'',pwd:'',role:''}]})
  const [data2, setData2] = useState({username:'', email: '', pwd:'',role:''})
  const [selectedRole, setSelectedRole] = useState('')
  const [justLogined, setJustLogined] = useState(false)
  const [tokenChecked, setTokenChecked]=useState(false)
  const [timer, setTimer] = useState('')
  const [isExpired, setIsExpired] = useState(true)
  const [updateUserId, setUpdateUserId] = useState(null)
  const [edit, setEdit] = useState(false)
  const [edit2, setEdit2] = useState(false)
  const pgDBToken = localStorage.getItem('pg_token')
  //User data
  const Ref = useRef(null)
  useEffect(() => {
    console.log('data.role', props.pgLoginData1)
    // when user clicked login
    if (props.pgLoginData1 !== null) {
      //get tokenInfo, user, users
      const handleSubmit = async (e) => {
        if(justLogined === false){
          //setMsg(props.pgLoginData1.msg)
          setData(props.pgLoginData1)
          await setData(props.pgLoginData1)
          setJustLogined(true)
        }
        function expiredMsg() {
          setMsg('Token expired. Please login again')
          localStorage.removeItem('pg_token')
          setTimeout(() => {
            window.location.reload()
          }, 6000)
        }
        setTimeout(expiredMsg, Number((props.pgLoginData1.exp - props.pgLoginData1.iat)*1000))
        Ref.current = setInterval(() => {
        let x = new Date()
        const y = props.pgLoginData1.exp*1000 - Date.parse(new Date())
        if (y >= 0) {
          setTimer((Math.floor((y / 1000 / 60 / 60)) > 1 ? Math.floor((y / 1000 / 60 / 60)) + 'days ' :
          Math.floor((y / 1000 / 60 / 60)) + 'day ') + (Math.floor((y / 1000 / 60 / 60) % 24) > 9 ? Math.floor((y / 1000 / 60 / 60) % 24) :
          '0' + Math.floor((y / 1000 / 60 / 60) % 24)) +':' + (Math.floor((y / 1000 / 60) % 60) > 9? Math.floor((y / 1000 / 60) % 60):
          '0' + Math.floor((y / 1000 / 60) % 60)) +':' +(Math.floor((y / 1000) % 60) > 9 ? Math.floor((y / 1000) % 60) :
          '0' + Math.floor((y / 1000) % 60)))
        }
        }, 1000)
      }
      setTimeout(handleSubmit, 5)
    }
    // when user refleshed page
    if(props.pgLoginData1 === null && tokenChecked === false){
      const handleSubmit = async (e) => {
      try {
      const {data:res} = await axios.post(`${APIURL}/api/pg/tokencheck`, {token:pgDBToken})
      setTokenChecked(true)
      if(res.msg !==''){
      setMsg(res.msg)
      const remove = setTimeout(() => {
      setMsg('')
      localStorage.removeItem('pg_token')
      window.location.reload()
      }, 5000)
      remove()
      }else{
      await setData({
      id:res.user.id,
      username:res.user.username,
      email:res.user.email,
      pwd:res.user.pwd,
      role:res.user.role,
      msg:res.msg,
      iatstring:new Date(res.tokenInfo.iat*1000).toString(),
      expstring:new Date(res.tokenInfo.exp*1000).toString(),
      iat:res.tokenInfo.iat,
      exp:res.tokenInfo.exp,
      justLogined:'true',
      usersdata:res.users
      })
      const expireTime = Number((res.tokenInfo.exp - res.tokenInfo.iat)*1000)
      function expiredMsg() {
      setMsg('Token expired. Please login again')
      setTimeout(() => {
      localStorage.removeItem('pg_token')
      window.location.reload()
      }, 6000)
      }
      setTimeout(expiredMsg,expireTime)
      Ref.current = setInterval(() => {
      let x = new Date()
      const y = res.tokenInfo.exp*1000 - Date.parse(new Date())
      if (y >= 0) {
      setTimer((Math.floor((y / 1000 / 60 / 60)) > 1 ? Math.floor((y / 1000 / 60 / 60)) + 'days ' : Math.floor((y / 1000 / 60 / 60)) + 'day ') + (Math.floor((y / 1000 / 60 / 60) % 24) > 9 ? Math.floor((y / 1000 / 60 / 60) % 24) : '0' + Math.floor((y / 1000 / 60 / 60) % 24)) +':' + (Math.floor((y / 1000 / 60) % 60) > 9? Math.floor((y / 1000 / 60) % 60): '0' + Math.floor((y / 1000 / 60) % 60)) +':' +(Math.floor((y / 1000) % 60) > 9 ? Math.floor((y / 1000) % 60) : '0' + Math.floor((y / 1000) % 60)))
      }
      }, 1000)
      }
      } catch (err) {
      console.error("Error")
      }
    }
    setTimeout(handleSubmit, 5)
  }
  }, [data])
  const onClick2CloseMsg = async (e) => {
  e.preventDefault()
  setMsg('')
  }
  const onClick2CloseErrMsg = async (e) => {
    e.preventDefault()
    setErrMsg('')
  }
  const onChange2Input = ({ currentTarget: input }) => {
    setData2({ ...data2, [input.name]: input.value })
}
  const onChange2Select =async(x) => {
    await setSelectedRole(x)
  }
  const onClick2Logout = (e) => {
    e.preventDefault()
    localStorage.removeItem('pg_token')
    setMsg('See you soon!');
    window.location.reload()
  }
  // normal user data
  const onClick2MyAccountEdit = async(x) => {
    await setEdit(!edit)
    setData2(x)
  }
  const onSubmit2MyAccountUpdate = async (e) => {
    e.preventDefault()
    try {
      const url = `${APIURL}/api/pg/userdata/update`
      const { data: res } = await axios.post(url, {role:'normal user', data2:data2})
      console.log(res)
      setMsg(res.msg)
      await setData({...data, username:res.data2.username, email:res.data2.email, pwd:res.data2.pwd, role:res.data2.role})
      setEdit(false)
      const remove = setTimeout(() => {
        setMsg('')
      }, 2500)
      return () => clearTimeout(remove)
    } catch (err) {
      if (err.response && err.response.status >= 400 && err.response.status <= 500) {
        setErr(err.response.data.err_msg)
      }
    }
  }
  const onClick2MyAccountDelete = async (e) => {
      e.preventDefault()
      console.log('delete button clicked')
      try {
        const { data: res } = await axios.post(`${APIURL}/api/pg/userdata/delete`, data)
        localStorage.removeItem('pg_token')
        setMsg(res.msg)
        setTimeout(() => {
          window.location.reload()
        }, 2500)
      } catch (err) {
        if (err.response && err.response.status >= 400 && err.response.status <= 500) {
          setErr(err.response.data.msg)
        }
      }
  }
  // admin - user data
  const onClick2UserDataCancel = async (e) => {
    e.preventDefault()
    setEdit2(false)
}
const onClick2UserEdit = async(x) => {
    await setEdit2(true)
    console.log('edit btn clicked:',x)
    // setData2({ _id:x._id, username:x.username, email:x.email, pwd:x.pwd, role:x.role })
    setData2(x)
}
const onClick2UserUpdate = async (e) => {
  e.preventDefault()
  const data2_2 = {
    id:data2.id,
    username:data2.username,
    pwd:data2.pwd,
    email:data2.email,
    role:selectedRole
  }
  try {
    const { data: res } = await axios.post( `${APIURL}/api/pg/userdata/update`, {role:'admin', data2:data2_2})
    setMsg(res.msg)
    await setData({...data, username:res.data2.username, email:res.data2.email, pwd:res.data2.pwd, role:res.data2.role, usersdata:res.data3})
    setTimeout(() => {
      setMsg('')
      setEdit2(false)
    }, 1)
  } catch (err) {
    if (err.response && err.response.status >= 400 && err.response.status <= 500) {
      setErr(err.response.data.msg)
    }
  }
}
const onClick2UserDelete = async (e) => {
  e.preventDefault()
  try {
    const { data: res } = await axios.post(`${APIURL}/api/pg/userdata/delete`, data)
    localStorage.removeItem('pg_token')
    setMsg(res.msg)
    setTimeout(() => {
      window.location.reload()
    }, 2500)
  } catch (err) {
    if (err.response && err.response.status >= 400 && err.response.status <= 500) {
      setErr(err.response.data.msg)
    }
  }
}
  // admin - users data
  const onClick2UsersEdit = async (x) => {
      await setUpdateUserId(x.id)
      await setSelectedRole(x.role)
      setData2(x)
  }
  const onClick2UsersCancel = async (e) => {
      e.preventDefault()
      setUpdateUserId('')
      setData2({ lastname:'', firstname:'', email: '', pwd: '', role:'' })
  }
  const onClick2UsersUpdate = async (e) => {
    e.preventDefault()
    const data4 = {
      ...data2,
      role: selectedRole
    }
    try {
      //get tokenInfo, user, users
      console.log('users update clicked')
      const { data: res } = await axios.post(`${APIURL}/api/pg/usersdata/update`, data4)
      setMsg(res.msg)
      //testing testing start
      if(data4.id === data2.id){
        setData({
          ...data,
          username:res.data2.username,
          email:res.data2.email,
          pwd:res.data2.pwd,
          role:res.data2.role,
          usersdata:res.data3
        })
      }
      setData({...data, usersdata:res.data3})
      setUpdateUserId(null)
      //testing testing end
      const remove = setTimeout(() => {
        setMsg('')
      }, 3000)
      return () => clearTimeout(remove)
    } catch (err) {
      if (err.response && err.response.status >= 400 && err.response.status <= 500) {
        setErrMsg(err.response.data.msg)
      }
    }
    setErr('')
  }
  const onClick2UsersDelete = async(e, x, y) => {
    e.preventDefault()
    try{
      const { data: res } = await axios.post(`${APIURL}/api/pg/usersdata/delete`, {id:x, username:y})
    setData({...data, usersdata:res.data3})
    setMsg(res.msg)
  } catch(err){
    if (err.response && err.response.status >= 400 && err.response.status <= 500) {
        setErrMsg(err.response.msg)
    }
  }
  }
  return (
    <>
      <div className='login-container'>
      <div className='notification-container'>
      {msg && <div className='notification flex flex-row justify-between'><div>{msg}</div><div><IoIosClose onClick={onClick2CloseMsg}/></div></div>}
      {errMsg && <div className='account-err-msg flex flex-row justify-between'><div>{errMsg}</div><div><IoIosClose onClick={onClick2CloseErrMsg}/></div></div>}
      </div>
      {data.role === 'admin'? <div>
        <div className='login-nav flex-row'>
        <div>PosgreSQL Main <span>Admin</span></div>
        <div className='flex'>
          <div className='logout-container'>
          Login Token Ticket:<br/> {new Date(data.iatstring).getUTCMonth() + 1}.{new Date(data.iatstring).getUTCDate()}.{new Date(data.iatstring).getUTCFullYear()} {new Date(data.iatstring).toLocaleTimeString()} - {new Date(data.expstring).getUTCMonth() + 1}.{new Date(data.expstring).getUTCDate()}.{new Date(data.expstring).getUTCFullYear()} {new Date(data.expstring).toLocaleTimeString()}<br/>
          <IoTimeSharp />{timer} left
          </div>
        <div>
          <button
            className='logout-btn flex'
            onClick={onClick2Logout}
          ><FiLogOut />Logout</button>
          </div>
        </div>
        </div>
        <h4>Member Accounts</h4>
        <div className='login-admin-container'>
          <div className='ttl flex justify-items-start'>
            <div></div>
            <div>Account ID</div>
            <div>Username</div>
            <div>Email</div>
            <div>Pwd</div>
            <div>Role</div>
            <div></div>
          </div>
          <>{(data.usersdata).map((x, key) =>
            <>{updateUserId !== x.id?
            <div className='item flex flex-row justify-items-start'>
            <div>{key+1}</div>
            <div>{x.id}</div>
            <div>{x.username}</div>
            <div>{x.email}</div>
            <div>{x.pwd}</div>
            <div>{x.role}</div>
          <div className='flex btn-pc'><button onClick={()=> onClick2UsersEdit(x)} className='edit-btn'>Edit</button>
          <button onClick={(e)=> onClick2UsersDelete(e,x.id,x.username)} className='delete2-btn'>Delete</button></div>
          <div className='flex btn-sp'>
          <div className='edit2-btn' style={{maskImage: `url("data:image/svg+xml,${svgStringMdEdit}")`}}>
          <div className='edit2-btn-bg' onClick={()=> onClick2UsersEdit(x)}></div></div>
          <div className='delete3-btn' style={{maskImage: `url("data:image/svg+xml,${svgStringIoIosClose}")`}}>
          <div className='delete3-btn-bg' onClick={(e)=> onClick2UsersDelete(e,x.id,x.username)}></div></div></div>
          </div> : <div className='item-edit flex flex-row justify-items-start text-left'>
          <div>{key+1}</div>
          <div>{x.id}</div>
          <div><input className='inputA' type='text' name='username' defaultValue={x.username} onChange={onChange2Input} />
          </div>
            <div><input className='inputA' type='email' name='email' defaultValue={x.email} onChange={onChange2Input} /></div>
            <div><input className='inputA' type='text' name='pwd' defaultValue={x.pwd} onChange={onChange2Input} /></div>
            <div><select name="role" defaultValue={x.role}
            //value={selectedRole}
            onChange={(e) => onChange2Select(e.target.value)}>
            {/* <option hidden value="default">{x.role}</option> */}
            <option value="admin">Admin</option>
            <option value="normal member">Normal Member</option>
            <option value="premiere member">Premiere Member</option>
            </select></div>
            {err && <div className='create-new-account-err'>{err}</div>}
            <div className='flex'>
            <button onClick={onClick2UsersCancel} type='button'className='cancel-btn'>Cancel</button>
            <button onClick={onClick2UsersUpdate} type='button' className='update2-btn'
            disabled={!(data2.email && data2.pwd && data2.username)}>Update</button></div>
            </div>
            }</>
          )}</>
          <br/><br/>
          <h4>Your Account</h4>
          <div className='login-admin-container-user'>
            <div className='ttl flex justify-items-start'>
            <div></div>
            <div>Account ID</div>
            <div>Username</div>
            <div>Email</div>
            <div>Pwd</div>
            <div>Role</div>
            <div></div>
            </div>
          {edit2 !== false ?
            <div className='item-edit flex justify-items-start text-left'>
            <div></div>
            <div className='text-left'>{data.id}</div>
            <div><input className='inputA' type='text' name='username' defaultValue={data.username} onChange={onChange2Input} /></div>
            <div><input className='inputA' type='email' name='email' defaultValue={data.email} onChange={onChange2Input} /></div>
            <div><input className='inputA' type='text' name='pwd' defaultValue={data.pwd} onChange={onChange2Input} /></div>
            <div><select name="role" defaultValue={data.role}
            onChange={(e) => onChange2Select(e.target.value)}>
            <option value="admin">Admin</option>
            <option value="normal member">Normal Member</option>
            <option value="premiere member">Premiere Member</option>
            </select></div>
            <div className='flex'><button
            className='cancel-btn' onClick={onClick2UserDataCancel}
            >Cancel</button><button
            onClick={onClick2UserUpdate}
              className='text-left update2-btn'
              type='button'
              disabled={!(data2.username&&data2.email&&data2.pwd)}
            >Update</button></div>
            {/* {err && <div className='create-new-account-err'>{err}</div>} */}
          </div> : <div className='item flex flex-row justify-items-start'><div></div><div>{data.id}</div><div>{data.username}</div><div>{data.email}</div><div>{data.pwd}</div><div>{data.role}</div><div className='flex btn-pc'><button className='edit-btn' onClick={()=> onClick2UserEdit(data)}
          >Edit</button><button className='delete2-btn' onClick={onClick2UserDelete}>Delete Account</button></div><div className='flex btn-sp'>
          <div className='edit2-btn' style={{maskImage: `url("data:image/svg+xml,${svgStringMdEdit}")`}}>
          <div className='edit2-btn-bg' onClick={()=> onClick2UserEdit(data)}></div></div>
          <div className='delete3-btn' style={{maskImage: `url("data:image/svg+xml,${svgStringIoIosClose}")`}}>
          <div className='delete3-btn-bg' onClick={onClick2UserDelete}></div></div></div></div>}
          </div>
          <br />
        </div>
        </div>:<>
        <div className='login-nav'>
          <div>PosgreSQL Main</div>
          <div className='flex'>
          <div className='logout-container'>
           • This Login Token is available for <br/> {new Date(data.iatstring).getUTCMonth() + 1}.{new Date(data.iatstring).getUTCDate()}.{new Date(data.iatstring).getUTCFullYear()} {new Date(data.iatstring).toLocaleTimeString()} - {new Date(data.expstring).getUTCMonth() + 1}.{new Date(data.expstring).getUTCDate()}.{new Date(data.expstring).getUTCFullYear()} {new Date(data.expstring).toLocaleTimeString()}<br/>
          • You have {timer} left
          </div>
        <div>
          <button
            className='logout-btn flex'
            onClick={onClick2Logout}
          ><FiLogOut />Logout</button>
          </div>
        </div>
        </div>
        <div className='login-contents-container'
        >
          <div className='menu-item mb-4'><b>Change account details</b>
           {edit ? <button className='cancel-btn'onClick={() => setEdit(!edit)}>Cancel</button>:<button className='cancel-btn'
            onClick={()=> onClick2MyAccountEdit(data)}>Edit</button>}
          </div>
          {edit ? <form onSubmit={onSubmit2MyAccountUpdate}>
            <b>Account ID:{data.id}</b>
            <div>
            <label>Username</label><br/>
            <input className='create-new-account-input' type='text' name='username' defaultValue={data2.username} onChange={onChange2Input} />
            </div>
            <div>
            <label>Email Address</label><br/>
            <input className='create-new-account-input' type='email' name='email' defaultValue={data2.email} onChange={onChange2Input} />
            </div>
            <div>
            <label>Password</label><br/>
            <input className='create-new-account-input' type='text' name='pwd' defaultValue={data2.pwd} onChange={onChange2Input} />
            </div>
            Role:{data2.role}<br/>
            {err && <div className='create-new-account-err'>{err}</div>}
            <button
            className='update-btn'
            type='submit'
            disabled={!(data2.username&&data2.email&&data2.pwd)}
            >Update</button>
          </form> : <>Account ID:{data.id}<br />Username:{data.username}<br />Email Address:{data.email}<br />Password: {props.pgLoginData1 !== null? <>{data.pwd}</>:'encrypted'}
          <br />Role:{data.role}</>}
          <br />
          <div className='menu-item'>
            <b>Delete Account</b><br />
            <button
              className='delete-btn'
              onClick={onClick2MyAccountDelete}
            >Delete Account</button>
          </div>
        </div></>}

      </div>
    </>
  )
}
const SQLLogin = ({ sqlLoginData1 }) => {
  const [msg, setMsg] = useState('')
  const [errMsg, setErrMsg] = useState('')
  const [err, setErr] = useState('')
  const [data, setData] = useState({username:'', email: '', role:'', iatstring:'', expstring:'',iat:'', exp:'', usersdata:[{id:'',firstname:'',lastname:'',email:'',pwd:'',role:''}]})
  const [encryption, setEncryption] = useState({ id: '', encryption: '', pwd: '' })
  const [justLogined, setJustLogined] = useState(false)
  const [isExpired, setIsExpired] = useState(true)
  const [page, setPage] = useState('MySQL Login')
  const sqlToken = localStorage.getItem('sql_token')
  useEffect(() => {
    console.log('from sql login page - refleshed!',sqlToken)
    const remove = setTimeout(() => {
      setMsg('')
    }, 3000)
    return () => clearTimeout(remove)
  }, [encryption])
  //login form
  const onChangeInput = ({ currentTarget: input }) => { setData({ ...data, [input.name]: input.value }) }
  //one time passcode form
  const onChange2Input = ({ currentTarget: input }) => { setData2({ ...data2, [input.name]: input.value }) }
  //set new pwd form
  const onChange3Input = ({ currentTarget: input }) => { setData3({ ...data3, id: data2.id, [input.name]: input.value }) }
  const onClick2CloseMsg = async (e) => {
    e.preventDefault()
    setMsg('')
  }
  const onClick2CloseErrMsg = async (e) => {
    e.preventDefault()
    setErrMsg('')
  }
  const onSubmit2Login = async (e) => {
    e.preventDefault()
    try{
      const {data:res} = await axios.post(`${APIURL}/api/sql/login`, data)
      console.log('login btn clicked', res)
      localStorage.setItem('sql_token', res.tokenInfo.token)
      sqlLoginData1({
        id:res.user.id,
        username:res.user.username,
        email:data.email,
        pwd:data.pwd,
        role:res.user.role,
        msg:res.msg,
        iatstring:new Date(res.tokenInfo.iat*1000).toString(),
        expstring:new Date(res.tokenInfo.exp*1000).toString(),
        iat:res.tokenInfo.iat,
        exp:res.tokenInfo.exp,
        justLogined:'true',
        usersdata:res.users
    })
    setJustLogined(true)
        setMsg(res.msg)
        const remove = setTimeout(() => {
          setMsg('')
        }, 3000)
        return () => clearTimeout(remove)
    } catch (err) {
      if (err.response && err.response.status >= 400 && err.response.status <= 500) {
        setErrMsg(err.response.data.msg)
      }
    }
  }
  const onClick2Login = async (e) => {
    e.preventDefault()
    setData({ email: '', pwd: '' })
    setErr('')
    setPage('MySQL Login')
  }
  const onClick2Register = async (e) => {
    e.preventDefault()
    setData({ email: '', pwd: '' })
    setErr('')
    setPage('MySQL Create Account')
  }
  const onClick2ResetPwd = async (e) => {
    setMsg('')
    e.preventDefault()
    setPage('MySQL Reset Password')
  }
  const onSubmit2Register = async (e) => {
    e.preventDefault()
    try {
    const { data: res } = await axios.post(`${APIURL}/api/sql/register`, data)
      setMsg(res.msg)
      setData({ email: '', pwd: '' })
      setErr('')
      setPage('MySQL Login')
    } catch (err) {
      if (err.response && err.response.status >= 400 && err.response.status <= 500) {
        setErr(err.response.data.msg)
      }
    }
  }
  //reset pwd
  const onSubmit2Send = async (e) => {
    e.preventDefault()
    setErr('')
    const fetchData = async () => {
      //email check
      await axios.post(`${APIURL}/api/sql/password-reset-mail`, data)
        .then(async res => {
          if (res.data.data.id)
            if (!err) {
              //issue token & send mail
              setData2({ id: res.data.data.id })
              await axios.post(`${APIURL}/api/sql/password-reset-mail2`, {
                id: res.data.data.id,
                email: data.email
              })
                .then(async res => {
                  setMsg(res.data.msg)
                }).catch(function (err) {
                  setErr('System Error. Please try it again later.')
                })
            }
        }).catch(function (err) {
          setErr(err.response.data.msg)
        })
    }
    fetchData()
  }
  //set pwd
  const [data2, setData2] = useState({
    id: '',
    token: ''
  })
  const [err2, setErr2] = useState('')
  const [data3, setData3] = useState({
    id: '',
    pwd: '',
    pwd2: ''
  })
  const onSubmit2OneTimePasscode = async (e) => {
    e.preventDefault()
    try {
      const payload = JSON.parse(window.atob(data2.token.split('.')[1]))
      console.log('payload', payload)
      console.log('Exp', new Date(payload.exp * 1000))
      const isExpired = Date.now() >= payload.exp * 1000
      console.log('isExpired', isExpired)
      console.log('Date.now()', Date.now())
      console.log('payload.exp*1000', payload.exp * 1000)
      console.log('Date.now()-payload.exp*1000', Date.now() - payload.exp * 1000)
      if (!isExpired) {
        setErr2('')
        console.log('account id', data2.id)
        setIsExpired(false)
      } else {
        setErr2('Token Expired')
      }
    } catch (err) {
      console.log('System Error', err)
    }
  }
  const onSubmit2NewPwd = async (e) => {
    e.preventDefault()
    console.log('data3', data3)
    try {
      const url = `${APIURL}/api/sql/set-password-again`
      const { data: res } = await axios.post(url, data3)
      setMsg(res.msg)
      setPage('MySQL Login')
      const remove = setTimeout(() => {
        setMsg('')
      }, 2500)
      return () => clearTimeout(remove)
    } catch (err) {
      console.log('System Error', err)
    }
  }
  const optionTabs = [
    {
      ttl: 'MySQL Login',
      table: <><div className='login-container'>
        <div className='notification-container'>
          {msg && <div className='notification flex flex-row justify-between'><div>{msg}</div><div><IoIosClose onClick={onClick2CloseMsg}/></div></div>}
          {errMsg && <div className='account-err-msg flex flex-row justify-between'><div>{errMsg}</div><div><IoIosClose onClick={onClick2CloseErrMsg}/></div></div>}
        </div>
        <div className='login-nav'>MySQL Login</div>
        <div className='login-input-container'>
          {Object.values(encryption)}
          <form onSubmit={onSubmit2Login}>
            <div className='text-left'>
              <label>Account Email</label><br/>
              <input className='create-new-account-input' type='email' name='email' value={data.email} onChange={onChangeInput} />
            </div>
            <div className='text-left'>
              <label>Password</label><br/>
              <input className='create-new-account-input' type='text' name='pwd' value={data.pwd} onChange={onChangeInput} />
            </div>
            {err && <div className='create-new-account-err'>{err}</div>}
            <button type='submit' className='login-btn' disabled={!(data.email && data.pwd)}
            >Login</button>
          </form>
          <button onClick={onClick2Register} className='link-btn'>Create your account</button>
          <button onClick={onClick2ResetPwd} className='link-btn'>Reset password</button>
        </div>
      </div></>
    },
    {
      ttl: 'MySQL Create Account',
      table: <><div className='login-container'>
        <div className='notification-container'>
        {msg && <div className='notification flex flex-row justify-between'><div>{msg}</div><div><IoIosClose onClick={onClick2CloseMsg}/></div></div>}
        {errMsg && <div className='account-err-msg flex flex-row justify-between'><div>{errMsg}</div><div><IoIosClose onClick={onClick2CloseErrMsg}/></div></div>}
        </div>
        <div className='login-nav'>MySQL Register</div>
        <div className='login-input-container'>
          <form onSubmit={onSubmit2Register}>
            <div className='text-left'>
              <label>Username</label><br/>
              <input className='create-new-account-input' type='text' name='username' value={data.username} onChange={onChangeInput} />
            </div>
            <div className='text-left'>
              <label>Email Address</label><br/>
              <input className='create-new-account-input' type='email' name='email' value={data.email} onChange={onChangeInput} />
            </div>
            <div className='text-left'>
              <label>Set Password</label><br/>
              <input className='create-new-account-input' type='text' name='pwd' value={data.pwd} onChange={onChangeInput} />
            </div>
            {err && <div className='create-new-account-err'>{err}</div>}
            <button
              className='register-btn'
              type='submit'
              disabled={!(data.username && data.email && data.pwd)}
            >Register</button>
          </form>
          <button onClick={onClick2Login} className='link-btn'>Already have account? Back to Login >></button>
        </div>
      </div></>
    },
    {
      ttl: 'MySQL Reset Password',
      table: <><div className='login-container'>
        <div className='account-msg-container'></div>
        <div className='login-nav'>MySQL Reset Password</div>
        {isExpired ? <>
          <div className='login-input-container'>
            <form onSubmit={onSubmit2Send}>
              <div className='text-left'>
                <label>Account Email</label><br/>
                <input className='create-new-account-input' type='email' name='email' value={data.email} onChange={onChangeInput} />
              </div>
              {err && <div className='account-err'>{err}</div>}
              <button className='login-btn' disabled={!(data.email)}>Get Onetime Passcode</button></form>
            {msg && <div className='account-sub-msg'>{msg}</div>}
          </div>
          <br />
          <div className='login-input-container'>
            <form onSubmit={onSubmit2OneTimePasscode}>
              <div>
                <label>Account ID</label>
                <input className='create-new-account-input' type='name' name='id' value={data2.id} onChange={onChange2Input} />
              </div>
              <div>
                <label>Onetime Passcode</label>
                <input className='create-new-account-input' type='text' name='token' value={data2.token} onChange={onChange2Input} />
              </div>
              {err && <div className='create-new-account-err'>{err}</div>}
              <button className='login-btn'
                disabled={!(data2.id && data2.token)}
              >Reset Password</button></form>
            {/* Token Expired. Please send reset password mail again. */}
            <button onClick={onClick2Login} className='link-btn'>Go Back</button>
            <button onClick={onClick2Register} className='link-btn'>Create your account</button>
          </div>
        </> : <>
          <div className='login-input-container'>
            <form onSubmit={onSubmit2NewPwd}>
              <div className='login-input-ttl'>Please set your new password</div>
              <div className='login-input-txt'>
                <label>Account Id</label>
                {data2.id}
              </div>
              <div>
                <label>Password</label>
                <input className='create-new-account-input' type='text' name='pwd' value={data3.pwd} onChange={onChange3Input} />
              </div>
              <div>
                <label>Confirm Password</label>
                <input className='create-new-account-input' type='text' name='pwd2' value={data3.pwd2} onChange={onChange3Input} />
              </div>
              {(data3.pwd2.length != 0) && data3.pwd != data3.pwd2 && <div className='create-new-account-err'>Please input same password</div>}
              {err && <div className='create-new-account-err'>{err}</div>}
              <button className='login-btn' disabled={!(data3.pwd && data3.pwd2)}>Send</button></form>
            <button className='link-btn' onClick={onClick2ResetPwd}>Go Back</button>
            <button className='link-btn' onClick={onClick2Register}>Create your account</button>
          </div>
        </>}
      </div>
      </>
    }
  ]
  return (
    <>
      {page === 'MySQL Login' ? <>{optionTabs[0].table}</> : <>{page === 'MySQL Create Account' ? <>{optionTabs[1].table}</> : <>{page === 'MySQL Reset Password' ? <>{optionTabs[2].table}</> : <>{optionTabs[3].table}</>}</>}</>}
    </>
  )
}
const SQL = (props) => {
  const [msg, setMsg] = useState('')
  const [errMsg, setErrMsg] = useState('')
  const [err, setErr] = useState('')
  const [data, setData] = useState({username:'', email: '', pwd:'', role:'', iatstring:'', expstring:'',iat:'', exp:'', usersdata:[{_id:'',username:'',email:'',pwd:'',role:''}]})
  const [data2, setData2] = useState({username:'', email: '', pwd:'',role:''})
  const [selectedRole, setSelectedRole] = useState('')
  const [justLogined, setJustLogined] = useState(false)
  const [tokenChecked, setTokenChecked]=useState(false)
  const [timer, setTimer] = useState('')
  const [isExpired, setIsExpired] = useState(true)
  const [updateUserId, setUpdateUserId] = useState(null)
  const [edit, setEdit] = useState(false)
  const [edit2, setEdit2] = useState(false)
  const sqlToken = localStorage.getItem('sql_token')
  //User data
  const Ref = useRef(null)
  useEffect(() => {
    // when user clicked login
    if (props.sqlLoginData1 !== null) {
      //get tokenInfo, user, users
      const handleSubmit = async (e) => {
        if(justLogined === false){
          setMsg(props.sqlLoginData1.msg)
          setData(props.sqlLoginData1)
          await setData(props.sqlLoginData1)
          setJustLogined(true)
        }
        function expiredMsg() {
          setMsg('Token expired. Please login again')
          localStorage.removeItem('sql_token')
          setTimeout(() => {
            window.location.reload()
          }, 6000)
        }
        setTimeout(expiredMsg, Number((props.sqlLoginData1.exp - props.sqlLoginData1.iat)*1000))
        Ref.current = setInterval(() => {
        let x = new Date()
        const y = props.sqlLoginData1.exp*1000 - Date.parse(new Date())
        if (y >= 0) {
          setTimer((Math.floor((y / 1000 / 60 / 60)) > 1 ? Math.floor((y / 1000 / 60 / 60)) + 'days ' :
          Math.floor((y / 1000 / 60 / 60)) + 'day ') + (Math.floor((y / 1000 / 60 / 60) % 24) > 9 ? Math.floor((y / 1000 / 60 / 60) % 24) :
          '0' + Math.floor((y / 1000 / 60 / 60) % 24)) +':' + (Math.floor((y / 1000 / 60) % 60) > 9? Math.floor((y / 1000 / 60) % 60):
          '0' + Math.floor((y / 1000 / 60) % 60)) +':' +(Math.floor((y / 1000) % 60) > 9 ? Math.floor((y / 1000) % 60) :
          '0' + Math.floor((y / 1000) % 60)))
        }
        }, 1000)
      }
      setTimeout(handleSubmit, 5)
    }
    // when user refleshed page
    if(props.sqlLoginData1 === null && tokenChecked === false){
      const handleSubmit = async (e) => {
      try {
      const {data:res} = await axios.post(`${APIURL}/api/sql/tokencheck`, {token:sqlToken})
      console.log('sql token check(page reloaded)',res)
      setTokenChecked(true)
      if(res.msg !==''){
      setMsg(res.msg)
      const remove = setTimeout(() => {
      setMsg('')
      localStorage.removeItem('sql_token')
      window.location.reload()
      }, 5000)
      remove()
      }else{
      await setData({
      id:res.user.id,
      username:res.user.username,
      email:res.user.email,
      pwd:res.user.pwd,
      role:res.user.role,
      msg:res.msg,
      iatstring:new Date(res.tokenInfo.iat*1000).toString(),
      expstring:new Date(res.tokenInfo.exp*1000).toString(),
      iat:res.tokenInfo.iat,
      exp:res.tokenInfo.exp,
      justLogined:'true',
      usersdata:res.users
      })
      const expireTime = Number((res.tokenInfo.exp - res.tokenInfo.iat)*1000)
      function expiredMsg() {
      setMsg('Token expired. Please login again')
      setTimeout(() => {
      localStorage.removeItem('sql_token')
      window.location.reload()
      }, 6000)
      }
      setTimeout(expiredMsg,expireTime)
      Ref.current = setInterval(() => {
      let x = new Date()
      const y = res.tokenInfo.exp*1000 - Date.parse(new Date())
      if (y >= 0) {
      setTimer((Math.floor((y / 1000 / 60 / 60)) > 1 ? Math.floor((y / 1000 / 60 / 60)) + 'days ' : Math.floor((y / 1000 / 60 / 60)) + 'day ') + (Math.floor((y / 1000 / 60 / 60) % 24) > 9 ? Math.floor((y / 1000 / 60 / 60) % 24) : '0' + Math.floor((y / 1000 / 60 / 60) % 24)) +':' + (Math.floor((y / 1000 / 60) % 60) > 9? Math.floor((y / 1000 / 60) % 60): '0' + Math.floor((y / 1000 / 60) % 60)) +':' +(Math.floor((y / 1000) % 60) > 9 ? Math.floor((y / 1000) % 60) : '0' + Math.floor((y / 1000) % 60)))
      }
      }, 1000)
      }
      } catch (err) {
      console.error("Error")
      }
    }
    setTimeout(handleSubmit, 5)
  }
  }, [data])
  const onClick2CloseMsg = async (e) => {
    e.preventDefault()
    setMsg('')
  }
  const onClick2CloseErrMsg = async (e) => {
    e.preventDefault()
    setErrMsg('')
  }
  const onChange2Input = ({ currentTarget: input }) => {
    setData2({ ...data2, [input.name]: input.value })
    console.log(data2.role, data2.username)
}
  const onClick2Logout = () => {
    localStorage.removeItem('sql_token')
    setMsg('See you soon!')
    window.location.reload()
  }
  // normal user data
  const onClick2MyAccountEdit = async(x) => {
    await setEdit(!edit)
    setData2(x)
  }
  const onSubmit2MyAccountUpdate = async (e) => {
    e.preventDefault()
    try {
      console.log('update btn send data', data2)
      const url = `${APIURL}/api/sql/userdata/update`
      const { data: res } = await axios.post(url, {role:'normal user', data2:data2})
      console.log(res)
      setMsg(res.msg)
      await setEdit(!edit)
      await setData({...data, username:res.data2.username, email:res.data2.email, pwd:res.data2.pwd, role:res.data2.role})
      setEdit(false)
      const remove = setTimeout(() => {
        setMsg('')
      }, 2500)
      return () => clearTimeout(remove)
    } catch (err) {
      if (err.response && err.response.status >= 400 && err.response.status <= 500) {
        setErr(err.response.data.msg)
      }
    }
  }
  const onClick2MyAccountDelete = async(e) => {
      e.preventDefault()
      console.log('delete button clicked')
      try {
        const { data: res } = await axios.post(`${APIURL}/api/sql/userdata/delete`, data)
        localStorage.removeItem('sql_token')
        setMsg(res.msg)
        setTimeout(() => {
          window.location.reload()
        }, 2500)
      } catch (err) {
        if (err.response && err.response.status >= 400 && err.response.status <= 500) {
          setErr(err.response.data.msg)
        }
      }
  }
  // admin - user data
  const onClick2UserCancel = async (e) => {
    e.preventDefault()
    setEdit2(false)
  }
  const onClick2UserEdit = async(x) => {
      await setEdit2(true)
      console.log('edit btn clicked:',x)
      // setData2({ _id:x._id, username:x.username, email:x.email, pwd:x.pwd, role:x.role })
      setData2(x)
  }
  const onClick2UserUpdate = async (e) => {
    e.preventDefault()
    const data2_2 = {
      id:data2.id,
      username:data2.username,
      pwd:data2.pwd,
      email:data2.email,
      role:selectedRole
    }
    try {
      const { data: res } = await axios.post( `${APIURL}/api/sql/userdata/update`, {role:'admin', data2:data2_2})
      setMsg(res.msg)
      await setData({...data, username:res.data2.username, email:res.data2.email, pwd:res.data2.pwd, role:res.data2.role, usersdata:res.data3})
      setTimeout(() => {
        setMsg('')
        setEdit2(false)
      }, 1)
    } catch (err) {
      if (err.response && err.response.status >= 400 && err.response.status <= 500) {
        setErr(err.response.data.msg)
      }
    }
  }
  const onClick2UserDelete = async (e) => {
    e.preventDefault()
    try {
      const { data: res } = await axios.post(`${APIURL}/api/sql/userdata/delete`, data)
      localStorage.removeItem('sql_token')
      setMsg(res.msg)
      setTimeout(() => {
        window.location.reload()
      }, 2500)
    } catch (err) {
      if (err.response && err.response.status >= 400 && err.response.status <= 500) {
        setErr(err.response.data.msg)
      }
    }
  }
  // admin - users data
  const onClick2UsersEdit = async (x) => {
      await setUpdateUserId(x.id)
      await setSelectedRole(x.role)
      setData2(x)
  }
  const onClick2UsersCancel = async (e) => {
      e.preventDefault()
      setUpdateUserId('')
      setData2({ lastname:'', firstname:'', email: '', pwd: '', role:'' })
  }
  const onClick2UsersUpdate = async (e) => {
    e.preventDefault()
    const data4 = {
      ...data2,
      role: selectedRole
    }
    try {
      //get tokenInfo, user, users
      console.log('users update clicked')
      const { data: res } = await axios.post(`${APIURL}/api/sql/usersdata/update`, data4)
      setMsg(res.msg)
      if(data.id === data2.id){
        await setData({
          ...data,
          username:res.data2.username,
          email:res.data2.email,
          pwd:res.data2.pwd,
          role:res.data2.role,
          usersdata:res.data3
        })
      }else{
        setData({...data, usersdata:res.data3})
      }
      setUpdateUserId(null)
      //testing testing end
      const remove = setTimeout(() => {
        setMsg('')
      }, 3000)
      return () => clearTimeout(remove)
    } catch (err) {
      if (err.response && err.response.status >= 400 && err.response.status <= 500) {
        setErrMsg(err.response.data.msg)
      }
    }
    setErr('')
  }
  const onClick2UsersDelete = async(e, x, y) => {
    e.preventDefault()
    try{
      const { data: res } = await axios.post(`${APIURL}/api/sql/usersdata/delete`, {id:x, username:y})
    setData({...data, usersdata:res.data3})
    setMsg(res.msg)
  } catch(err){
    if (err.response && err.response.status >= 400 && err.response.status <= 500) {
        setErrMsg(err.response.msg)
    }
  }
  }
  return (
    <>
      <div className='login-container'>
      <div className='notification-container'>
      {msg && <div className='notification flex flex-row justify-between'><div>{msg}</div><div><IoIosClose onClick={onClick2CloseMsg}/></div></div>}
      {errMsg && <div className='account-err-msg flex flex-row justify-between'><div>{errMsg}</div><div><IoIosClose onClick={onClick2CloseErrMsg}/></div></div>}
      </div>
      {data.role === 'admin'?<div>
        <div className='login-nav flex-row'>
        <div>MySQL Main <span>Admin</span></div>
        <div className='flex'>
          <div className='logout-container'>
          Login Token Ticket:<br/> {new Date(data.iatstring).getUTCMonth() + 1}.{new Date(data.iatstring).getUTCDate()}.{new Date(data.iatstring).getUTCFullYear()} {new Date(data.iatstring).toLocaleTimeString()} - {new Date(data.expstring).getUTCMonth() + 1}.{new Date(data.expstring).getUTCDate()}.{new Date(data.expstring).getUTCFullYear()} {new Date(data.expstring).toLocaleTimeString()}<br/>
          <IoTimeSharp />{timer} left
          </div>
        <div>
          <button
            className='logout-btn flex'
            onClick={onClick2Logout}
          ><FiLogOut />Logout</button>
          </div>
        </div>
        </div>
        <h4>Member Accounts</h4>
        <div className='login-admin-container'>
          <div className='ttl flex justify-items-start'>
            <div></div>
            <div>Account ID</div>
            <div>Username</div>
            <div>Email</div>
            <div>Pwd</div>
            <div>Role</div>
            <div></div>
          </div>
          <>{(data.usersdata).map((x, key) =>
            <>{updateUserId !== x.id?
            <div className='item flex flex-row justify-items-start'>
            <div>{key+1}</div>
            <div>{x.id}</div>
            <div>{x.username}</div>
            <div>{x.email}</div>
            <div>{x.pwd}</div>
            <div>{x.role}</div>
          <div className='flex btn-pc'><button onClick={()=> onClick2UsersEdit(x)} className='edit-btn'>Edit</button>
          <button onClick={(e)=> onClick2UsersDelete(e,x.id,x.username)} className='delete2-btn'>Delete</button></div>
          <div className='flex btn-sp'>
          <div className='edit2-btn' style={{maskImage: `url("data:image/svg+xml,${svgStringMdEdit}")`}}>
          <div className='edit2-btn-bg' onClick={()=> onClick2UsersEdit(x)}></div></div>
          <div className='delete3-btn' style={{maskImage: `url("data:image/svg+xml,${svgStringIoIosClose}")`}}>
          <div className='delete3-btn-bg' onClick={(e)=> onClick2UsersDelete(e,x.id,x.username)}></div></div></div>
          </div> : <div className='item-edit flex flex-row justify-items-start text-left'>
          <div>{key+1}</div>
          <div>{x.id}</div>
          <div><input className='inputA' type='text' name='username' defaultValue={x.username} onChange={onChange2Input} />
          </div>
            <div><input className='inputA' type='email' name='email' defaultValue={x.email} onChange={onChange2Input} /></div>
            <div><input className='inputA' type='text' name='pwd' defaultValue={x.pwd} onChange={onChange2Input} /></div>
            <div><select name="role" defaultValue={x.role}
            onChange={(e) => setSelectedRole(e.target.value)}
            >
            {/* <option hidden value="default">{x.role}</option> */}
            <option value="admin">Admin</option>
            <option value="normal member">Normal Member</option>
            <option value="premiere member">Premiere Member</option>
            </select></div>
            {err && <div className='create-new-account-err'>{err}</div>}
            <div className='flex'>
            <button onClick={onClick2UsersCancel} type='button'className='cancel-btn'>Cancel</button>
            <button onClick={onClick2UsersUpdate} type='button' className='update2-btn'
            disabled={!(data2.email && data2.pwd && data2.username)}>Update</button></div>
            </div>
            }</>
          )}</>
          <br/><br/>
          <h4>Your Account</h4>
          <div className='login-admin-container-user'>
            <div className='ttl flex justify-items-start'>
            <div></div>
            <div>Account ID</div>
            <div>Username</div>
            <div>Email</div>
            <div>Pwd</div>
            <div>Role</div>
            <div></div>
            </div>
          {edit2 !== false ?
            <div className='item-edit flex justify-items-start text-left'>
            <div></div>
            <div className='text-left'>{data.id}</div>
            <div><input className='inputA' type='text' name='username' defaultValue={data.username} onChange={onChange2Input} /></div>
            <div><input className='inputA' type='email' name='email' defaultValue={data.email} onChange={onChange2Input} /></div>
            <div><input className='inputA' type='text' name='pwd' defaultValue={data.pwd} onChange={onChange2Input} /></div>
            <div><select name="role" defaultValue={data.role}
            onChange={(e) => setSelectedRole(e.target.value)}
            >
            <option value="admin">Admin</option>
            <option value="normal member">Normal Member</option>
            <option value="premiere member">Premiere Member</option>
            </select></div>
            <div className='flex'><button
            className='cancel-btn' onClick={onClick2UserCancel}
            >Cancel</button><button
            onClick={onClick2UserUpdate}
              className='text-left update2-btn'
              type='button'
              disabled={!(data2.username&&data2.email&&data2.pwd)}
            >Update</button></div>
            {/* {err && <div className='create-new-account-err'>{err}</div>} */}
          </div> : <div className='item flex flex-row justify-items-start'><div></div><div>{data.id}</div><div>{data.username}</div><div>{data.email}</div><div>{data.pwd}</div><div>{data.role}</div><div className='flex btn-pc'><button className='edit-btn' onClick={()=> onClick2UserEdit(data)}
          >Edit</button><button className='delete2-btn' onClick={onClick2UserDelete}>Delete Account</button></div><div className='flex btn-sp'>
          <div className='edit2-btn' style={{maskImage: `url("data:image/svg+xml,${svgStringMdEdit}")`}}>
          <div className='edit2-btn-bg' onClick={()=> onClick2UserEdit(data)}></div></div>
          <div className='delete3-btn' style={{maskImage: `url("data:image/svg+xml,${svgStringIoIosClose}")`}}>
          <div className='delete3-btn-bg' onClick={onClick2UserDelete}></div></div></div></div>}
          </div>
          <br />
        </div>
        </div>
        : <>
      <div className='login-nav'>
          <div>MySQL Main</div>
          <div className='flex'>
          <div className='logout-container'>
           • This Login Token is available for <br/> {new Date(data.iatstring).getUTCMonth() + 1}.{new Date(data.iatstring).getUTCDate()}.{new Date(data.iatstring).getUTCFullYear()} {new Date(data.iatstring).toLocaleTimeString()} - {new Date(data.expstring).getUTCMonth() + 1}.{new Date(data.expstring).getUTCDate()}.{new Date(data.expstring).getUTCFullYear()} {new Date(data.expstring).toLocaleTimeString()}<br/>
          • You have {timer} left
          </div>
        <div>
          <button
            className='logout-btn flex'
            onClick={onClick2Logout}
          ><FiLogOut />Logout</button>
          </div>
        </div>
        </div>
        <div className='login-contents-container'
        >
          <div className='menu-item mb-4'><b>Change account details</b>
           {edit ? <button className='cancel-btn' onClick={() => setEdit(!edit)}>Cancel</button>:<button className='cancel-btn'
            onClick={()=> onClick2MyAccountEdit(data)}>Edit</button>}
          </div>
          {edit ? <form onSubmit={onSubmit2MyAccountUpdate}>
            <b>Account ID:{data.id}</b>
            <div>
            <label>Username</label><br/>
            <input className='create-new-account-input' type='text' name='username' defaultValue={data2.username} onChange={onChange2Input} />
            </div>
            <div>
            <label>Email Address</label><br/>
            <input className='create-new-account-input' type='email' name='email' defaultValue={data2.email} onChange={onChange2Input} />
            </div>
            <div>
            <label>Password</label><br/>
            <input className='create-new-account-input' type='text' name='pwd' defaultValue={data2.pwd} onChange={onChange2Input} />
            </div>
            Role:{data2.role}<br/>
            {err && <div className='create-new-account-err'>{err}</div>}
            <button
            className='update-btn'
            type='submit'
            disabled={!(data2.username&&data2.email&&data2.pwd)}
            >Update</button>
          </form> : <>Account ID:{data.id}<br />Username:{data.username}<br />Email Address:{data.email}<br />Password: {props.sqlLoginData1 !== null? <>{data.pwd}</>:'encrypted'}
          <br />Role:{data.role}</>}
          <br />
          <div className='menu-item'>
            <b>Delete Account</b><br />
            <button
              className='delete-btn'
              onClick={onClick2MyAccountDelete}
            >Delete Account</button>
          </div>
        </div>
      </>}
      </div>
    </>
  )
}
const MONGOLogin = ({ mongoLoginData1 }) => {
  const [msg, setMsg] = useState('')
  const [errMsg, setErrMsg] = useState('')
  const [err, setErr] = useState('')
  const [data, setData] = useState({username:'', email: '', role:'', iatstring:'', expstring:'',iat:'', exp:'', usersdata:[{_id:'',firstname:'',lastname:'',email:'',pwd:'',role:''}]})
  const [justLogined, setJustLogined] = useState(false)
  const [isExpired, setIsExpired] = useState(true)
  const [page, setPage] = useState('MongoDB Login')
  const mongoToken = localStorage.getItem('mongodb_token')
  useEffect(() => {
    console.log('from mongo login page - refleshed!',mongoToken)
    //when user refleshed page
    if(mongoToken !== null && data.exp === ''){
      const handleSubmit = async (e) => {
          try {
            const {data:res} = await axios.post(`${APIURL}/api/mongo/tokencheck`, {token:mongoToken})
            console.log('tokencheck response:',res.msg, res.data1, res.data2, res.data3)
            if(res.data.msg==='token expired'){
              setMsg('Token expired. Please login again')
              //localStorage.removeItem('mongodb_token')
              setPage('mongoDB Login')
            }else{
              console.log('mongoLoginData1 goes here')
                // await mongoLoginData1({
                // ...data,
                // // firstname:res.data.y.firstname,
                // // lastname:res.data.y.lastname,
                // // email:res.data.y.email,
                // // role: res.data.y.role,
                // // usersdata:res.data.z,
                // // iatstring:new Date(res.data.x.iat*1000).toString(), expstring:new Date(res.data.x.exp*1000).toString(),
                // // exp:res.data.x.exp

                // id:res.data2._id,
                // username:res.data2.username,
                // email:res.data2.email,
                // pwd:res.data2.pwd,
                // role:res.data2.role,
                // msg:res.msg,
                // iatstring:new Date(res.data1.iat*1000).toString(),
                // expstring:new Date(res.data1.exp*1000).toString(),
                // iat:res.data1.iat,
                // exp:res.data1.exp,
                // justLogined:'true',
                // usersdata:res.data3
                // })
            }
          } catch (err) {
            console.error("Error creating post:", err)
          }
      }
      setTimeout(handleSubmit, 5)
  }
  return
    const remove = setTimeout(() => {
      setMsg('')
    }, 3000)
    return () => clearTimeout(remove)
  }, [])
  const onChangeInput = ({ currentTarget: input }) => { setData({ ...data, [input.name]: input.value }) }
  const onChange2Input = ({ currentTarget: input }) => { setData2({ ...data2, [input.name]: input.value }) }
  const onChange3Input = ({ currentTarget: input }) => { setData3({ ...data3, id: data2.id, [input.name]: input.value }) }
  const onClick2CloseMsg = async (e) => {
    e.preventDefault()
    setMsg('')
  }
  const onClick2CloseErrMsg = async (e) => {
    e.preventDefault()
    setErrMsg('')
  }
  const onSubmit2Login = async (e) => {
    e.preventDefault()
    try {
      console.log('mongo login btn clicked', data)
      const { data: res1 } = await axios.post(`${APIURL}/api/mongo/login`, data)
      localStorage.setItem('mongodb_token', res1.token)
      console.log('onSubmit1LoginA button is clicked', res1)
      // mongoLoginData1({id:res1.id, username:res1.username, email:data.email, pwd:data.pwd, role:res1.role, msg:res1.msg})
      try {
        const { data: res2 } = await axios.post(`${APIURL}/api/mongo/userdata`, {token:res1.token, email:data.email})
        console.log('login bg response 2:', res2.status, res2.data1, res2.data2, res2.data3)
        const issuedDate = new Date(res2.data1.iat*1000)
        console.log(issuedDate, res2.data1.exp*1000)
        mongoLoginData1({
          id:res2.data2._id,
          username:res2.data2.username,
          email:data.email,
          pwd:data.pwd,
          role:res2.data2.role,
          msg:res1.msg,
          iatstring:new Date(res2.data1.iat*1000).toString(),
          expstring:new Date(res2.data1.exp*1000).toString(),
          iat:res2.data1.iat,
          exp:res2.data1.exp,
          justLogined:'true',
          usersdata:res2.data3
        })
        setJustLogined(true)
        setMsg(res1.msg)
        const remove = setTimeout(() => {
            setMsg('')
        }, 3000)
        return () => clearTimeout(remove)
    } catch (err) {
        if (err.response && err.response.status >= 400 && err.response.status <= 500) {
            setErrMsg(err.response.data.msg)
        }
      }
    } catch (err) {
      if (err.response && err.response.status >= 400 && err.response.status <= 500) {
        setErrMsg(err.response.data.msg)
      }
    }
  }
  const onClick2Login = async (e) => {
    e.preventDefault()
    setMsg('')
    setPage('MongoDB Login')
  }
  const onClick2Register = async (e) => {
    e.preventDefault()
    setMsg('')
    setData({ username:'', email: '', pwd: '' })
    setPage('MongoDB Create Account')
  }
  const onClick2ResetPwd = async (e) => {
    e.preventDefault()
    setMsg('')
    setIsExpired(true)
    setPage('MongoDB Reset Password')
  }
  //Register
  const onSubmit2Register = async (e) => {
    e.preventDefault()
    try {
      const url = `${APIURL}/api/mongo/register`
      console.log('register btn clicked sending data:',data)
      const { data: res } = await axios.post(url, data)
      setMsg(res.msg)
      setData({ username:'', email: '', pwd: '' })
      setErr('')
      setPage('MongoDB Login')
    } catch (err) {
      if (err.response && err.response.status >= 400 && err.response.status <= 500) {
        setErr(err.response.data.msg)
      }
    }
  }
  // reset pwd
  const onSubmit2Send = async (e) => {
    console.log('get onetime password button clicked')
    e.preventDefault()
    setErr('')
    await axios.post(`${APIURL}/api/mongo/password-reset-mail`, data)
      .then(async res => {
        console.log('mongodb response', res.data.id)
        setData2({ id: res.data.id })
        setMsg(res.data.msg)
        console.log(res.data.msg)
      }).catch(function (err) {
        setErr(err.response.data.err_msg)
      })
  }
  //set pwd
  const [data2, setData2] = useState({
    id: '',
    token: ''
  })
  const [err2, setErr2] = useState('')
  const [data3, setData3] = useState({
    id: '',
    pwd: '',
    pwd2: ''
  })
  const onSubmit2OneTimePasscode = async (e) => {
    e.preventDefault()
    try {
      const payload = JSON.parse(window.atob(data2.token.split('.')[1]))
      console.log('payload', payload)
      console.log('Exp', new Date(payload.exp * 1000))
      const isExpired = Date.now() >= payload.exp * 1000
      console.log('isExpired', isExpired)
      console.log('Date.now()', Date.now())
      console.log('payload.exp*1000', payload.exp * 1000)
      console.log('Date.now()-payload.exp*1000', Date.now() - payload.exp * 1000)
      if (!isExpired) {
        setErr2('')
        console.log('account id', data2.id)
        setIsExpired(false)
      } else {
        setErr2('Token Expired')
      }
    } catch (err) {
      console.log('System Error', err)
    }
  }
  const onSubmit2NewPwd = async (e) => {
    e.preventDefault()
    try {
      const url = `${APIURL}/api/mongo/set-password-again`
      const { data: res } = await axios.post(url, data3)
      setMsg(res.msg)
      setPage('MongoDB Login')
    } catch (err) {
      console.log('System Error', err)
    }
  }
  const optionTabs = [
    {
      ttl: 'MongoDB Login',
      table: <><div className='login-container'>
        <div className='notification-container'>
          {msg && <div className='notification flex flex-row justify-between'><div>{msg}</div><div><IoIosClose onClick={onClick2CloseMsg}/></div></div>}
          {errMsg && <div className='account-err-msg flex flex-row justify-between'><div>{errMsg}</div><div><IoIosClose onClick={onClick2CloseErrMsg}/></div></div>}
        </div>
        <div className='login-nav'>MongoDB Login</div>
        <div className='login-input-container flex-col'>
          <form onSubmit={onSubmit2Login}>
            <div className='text-left'>
              <label>Account Email</label><br/>
              <input className='create-new-account-input' type='email' name='email' value={data.email} onChange={onChangeInput} />
            </div>
            <div className='text-left'>
              <label>Password</label><br/>
              <input className='create-new-account-input' type='text' name='pwd' value={data.pwd} onChange={onChangeInput} />
            </div>
            {err && <div className='create-new-account-err'>{err}</div>}
            <button type='submit' className='login-btn' disabled={!(data.email && data.pwd)}
            >Login</button>
          </form>
          <button onClick={onClick2Register} className='link-btn'>Create your account</button>
          <button onClick={onClick2ResetPwd} className='link-btn'>Reset password</button>
        </div>
      </div></>
    },
    {
      ttl: 'MongoDB Create Account',
      table: <><div className='login-container'>
        <div className='notification-container'>
          {msg && <div className='notification flex flex-row justify-between'><div>{msg}</div><div><IoIosClose onClick={onClick2CloseMsg}/></div></div>}
          {errMsg && <div className='account-err-msg flex flex-row justify-between'><div>{errMsg}</div><div><IoIosClose onClick={onClick2CloseErrMsg}/></div></div>}
        </div>
        <div className='account-msg-container'>
          {msg && <div className='account-msg'>{msg}</div>}
        </div>
        <div className='login-nav'>MongoDB Register</div>
        <div className='login-input-container'>
          <form onSubmit={onSubmit2Register}>
            <div className='text-left'>
              <label>Username</label><br/>
              <input className='create-new-account-input' type='text' name='username' value={data.username} onChange={onChangeInput} />
            </div>
            <div className='text-left'>
              <label>Email Address</label><br/>
              <input className='create-new-account-input' type='email' name='email' value={data.email} onChange={onChangeInput} />
            </div>
            <div className='text-left'>
              <label>Set Password</label><br/>
              <input className='create-new-account-input' type='text' name='pwd' value={data.pwd} onChange={onChangeInput} />
            </div>
            {err && <div className='create-new-account-err'>{err}</div>}
            <button
              className='register-btn'
              type='submit'
              disabled={!(data.username && data.email && data.pwd)}
            >Register</button>
          </form>
          <button onClick={onClick2Login} className='link-btn'>Already have account? Back to Login</button>
        </div>
      </div></>
    },
    {
      ttl: 'MongoDB Reset Password',
      table: <><div className='login-container'>
        <div className='notification-container'>
          {msg && <div className='notification flex flex-row justify-between'><div>{msg}</div><div><IoIosClose onClick={onClick2CloseMsg}/></div></div>}
          {errMsg && <div className='account-err-msg flex flex-row justify-between'><div>{errMsg}</div><div><IoIosClose onClick={onClick2CloseErrMsg}/></div></div>}
        </div>
        <div className='account-msg-container'></div>
        <div className='login-nav'>MongoDB Reset Password</div>
        {isExpired ? <>
          <div className='login-input-container'>
            <form onSubmit={onSubmit2Send}>
              <div className='text-left'>
                <label>Account Email</label><br/>
                <input className='create-new-account-input' type='email' name='email' value={data.email} onChange={onChangeInput} />
              </div>
              {err && <div className='account-err'>{err}</div>}
              <button className='login-btn' disabled={!(data.email)}>Get Onetime Passcode</button></form>
            {msg && <div className='account-sub-msg'>{msg}</div>}
          </div>
          <br />
          <div className='login-input-container'>
            <form onSubmit={onSubmit2OneTimePasscode}>
              <div className='text-left'>
                <label>Account ID</label><br/>
                <input className='create-new-account-input' type='name' name='id' value={data2.id} onChange={onChange2Input} />
              </div>
              <div className='text-left'>
                <label>Onetime Passcode</label><br/>
                <input className='create-new-account-input' type='text' name='token' value={data2.token} onChange={onChange2Input} />
              </div>
              {err2 && <div className='create-new-account-err'>{err2}</div>}
              <button className='login-btn'
                disabled={!(data2.id && data2.token)}
              >Reset Password</button></form>
            <button className='link-btn' onClick={onClick2Login}>Go Back</button>
            <button className='link-btn' onClick={onClick2Register}>Create your account</button>
          </div>
        </> : <>
          <div className='login-input-container'>
            <form onSubmit={onSubmit2NewPwd}>
              <div className='login-input-ttl'>Please set your new password</div>
              <div className='login-input-txt'>
                <label>Account Id</label>
                {data2.id}
              </div>
              <div>
                <label>Password</label>
                <input className='create-new-account-input' type='text' name='pwd' value={data3.pwd} onChange={onChange3Input} />
              </div>
              <div>
                <label>Confirm Password</label>
                <input className='create-new-account-input' type='text' name='pwd2' value={data3.pwd2} onChange={onChange3Input} />
              </div>
              {(data3.pwd2.length != 0) && data3.pwd != data3.pwd2 && <div className='create-new-account-err'>Please input same password</div>}
              {err && <div className='create-new-account-err'>{err}</div>}
              <button className='login-btn' disabled={!(data3.pwd && data3.pwd2)}>Apply</button></form>
            <button className='link-btn' onClick={onClick2ResetPwd}>Go Back</button>
            <button className='link-btn' onClick={onClick2Register}>Create your account</button>
          </div>
        </>}
      </div>
      </>
    }
  ]
  return (
    <>
      {page === 'MongoDB Login' ? <>{optionTabs[0].table}</> : <>{page === 'MongoDB Create Account' ? <>{optionTabs[1].table}</> : <>{page === 'MongoDB Reset Password' ? <>{optionTabs[2].table}</> : <>{optionTabs[3].table}</>}</>}</>}
    </>
  )
}
const MONGO = (props) => {
  const [msg, setMsg] = useState('')
  const [errMsg, setErrMsg] = useState('')
  const [err, setErr] = useState('')
  const [data, setData] = useState({username:'', email: '', pwd:'', role:'', iatstring:'', expstring:'',iat:'', exp:'', usersdata:[{_id:'',username:'',email:'',pwd:'',role:''}]})
  const [data2, setData2] = useState({username:'', email: '', pwd:'',role:''})
  const [justLogined, setJustLogined] = useState(false)
  const [tokenChecked, setTokenChecked]=useState(false)
  const [timer, setTimer] = useState('')
  const [isExpired, setIsExpired] = useState(true)
  const [updateUserId, setUpdateUserId] = useState(null)
  const [edit, setEdit] = useState(false)
  const [edit2, setEdit2] = useState(false)
  const mongoToken = localStorage.getItem('mongodb_token')
  //User data
  const Ref = useRef(null)
  useEffect(() => {
    // when user clicked login
    if (props.mongoLoginData1 === null) {
      //localStorage.removeItem('mongodb_token')
      //return window.location.reload()
    } else {
      console.log('received user data', props.mongoLoginData1)
      //setMsg(props.mongoLoginData1.msg)
      const handleSubmit = async (e) => {
        console.log('received user data', props.mongoLoginData1.exp,props.mongoLoginData1.iat)
        if(justLogined === false){
          await setData(props.mongoLoginData1)
          setJustLogined(true)
        }
        const expireTime = Number((props.mongoLoginData1.exp - props.mongoLoginData1.iat)*1000)
        function expiredMsg() {
          setMsg('Token expired. Please login again')
          localStorage.removeItem('mongodb_token')
          setTimeout(() => {
            window.location.reload()
          }, 6000)
        }
        console.log('expireTime',expireTime)
        setTimeout(expiredMsg,expireTime)
        Ref.current = setInterval(() => {
        let x = new Date()
        const y = props.mongoLoginData1.exp*1000 - Date.parse(new Date())
        if (y >= 0) {
          setTimer((Math.floor((y / 1000 / 60 / 60)) > 1 ? Math.floor((y / 1000 / 60 / 60)) + 'days ' : Math.floor((y / 1000 / 60 / 60)) + 'day ') + (Math.floor((y / 1000 / 60 / 60) % 24) > 9 ? Math.floor((y / 1000 / 60 / 60) % 24) : '0' + Math.floor((y / 1000 / 60 / 60) % 24)) +':' + (Math.floor((y / 1000 / 60) % 60) > 9? Math.floor((y / 1000 / 60) % 60): '0' + Math.floor((y / 1000 / 60) % 60)) +':' +(Math.floor((y / 1000) % 60) > 9 ? Math.floor((y / 1000) % 60) : '0' + Math.floor((y / 1000) % 60)))
        }
        }, 1000)
      }
      setTimeout(handleSubmit, 5)
    }
    //when user refleshed page
    if(mongoToken !== null && data.exp === ''){
        const handleSubmit = async (e) => {
            try {
              if(tokenChecked === false){
                  const {data:res} = await axios.post(`${APIURL}/api/mongo/tokencheck`, {token:mongoToken})
                  setTokenChecked(true)
                  //console.log('mongo db token check:', res.msg, res.data1, res.data2, res.data3)
                  if(res.msg ==='token expired'){
                    setMsg('Token expired. Please login again')
                    // const remove = setTimeout(() => {
                    //   setMsg('')
                    // localStorage.removeItem('mongodb_token')
                    // window.location.reload()
                    // }, 5000)
                  }else{
                      await setData({
                      id:res.data2._id,
                      username:res.data2.username,
                      email:res.data2.email,
                      pwd:res.data2.pwd,
                      role:res.data2.role,
                      msg:res.msg,
                      iatstring:new Date(res.data1.iat*1000).toString(),
                      expstring:new Date(res.data1.exp*1000).toString(),
                      iat:res.data1.iat,
                      exp:res.data1.exp,
                      justLogined:'true',
                      usersdata:res.data3
                      })
                      const expireTime = Number((res.data1.exp - res.data1.iat)*1000)
                      function expiredMsg() {
                        setMsg('Token expired. Please login again')
                        // setData({firstname:'', lastname:'', role:'', email: '', exp:''})
                        // setTimer('')
                        setTimeout(() => {
                          localStorage.removeItem('mongodb_token')
                          window.location.reload()
                        }, 6000)
                      }
                      setTimeout(expiredMsg,expireTime)
                      Ref.current = setInterval(() => {
                      let x = new Date()
                      const y = res.data1.exp*1000 - Date.parse(new Date())
                      if (y >= 0) {
                        setTimer((Math.floor((y / 1000 / 60 / 60)) > 1 ? Math.floor((y / 1000 / 60 / 60)) + 'days ' : Math.floor((y / 1000 / 60 / 60)) + 'day ') + (Math.floor((y / 1000 / 60 / 60) % 24) > 9 ? Math.floor((y / 1000 / 60 / 60) % 24) : '0' + Math.floor((y / 1000 / 60 / 60) % 24)) +':' + (Math.floor((y / 1000 / 60) % 60) > 9? Math.floor((y / 1000 / 60) % 60): '0' + Math.floor((y / 1000 / 60) % 60)) +':' +(Math.floor((y / 1000) % 60) > 9 ? Math.floor((y / 1000) % 60) : '0' + Math.floor((y / 1000) % 60)))
                      }
                      }, 1000)
                    }
                    console.log('timer', timer)
              }
            } catch (err) {
              console.error("Error")
            }
        }
        setTimeout(handleSubmit, 5)
    }
    // const remove = setTimeout(() => {
    //   setMsg('')
    // }, 2500)
    // return () => clearTimeout(remove)
  }, [data])
  const onClick2CloseMsg = async (e) => {
    e.preventDefault()
    setMsg('')
  }
  const onClick2CloseErrMsg = async (e) => {
    e.preventDefault()
    setErrMsg('')
  }
  const onClick2Logout = () => {
    localStorage.removeItem('mongodb_token')
    setMsg('See you soon!')
    setTimeout(() => {
      window.location.reload()
    }, 2500)
  }
  //normal member - user data
  const onClick2MyAccountEdit = async(x) => {
    await setEdit(!edit)
    setData2(x)
  }
  const onSubmit2MyAccountUpdate = async (e) => {
    e.preventDefault()
    console.log('clicked my account data update',data2)
    try {
      const url = `${APIURL}/api/mongo/userdata/update`
      const { data: res } = await axios.post(url, {data2:data2})
      setMsg(res.msg)
      setData({ ...data, username:data2.username, email:data2.email, pwd:data2.pwd})
      setEdit(false)
      // const remove = setTimeout(() => {
      //   setMsg('')
      // }, 2500)
      // return () => clearTimeout(remove)
    } catch (err) {
      if (err.response && err.response.status >= 400 && err.response.status <= 500) {
        setErrMsg(err.response.data.msg)
      }
    }
  }
  const onClick2MyAccountDelete = async (e) => {
    e.preventDefault()
    try {
      const { data: res } = await axios.post(`${APIURL}/api/mongo/userdata/delete`, data)
      console.log('account deleted: ', res.msg)
      localStorage.removeItem('mongodb_token')
      setMsg(res.msg)
      setTimeout(() => {
        window.location.reload()
      }, 2500)
    } catch (err) {
      if (err.response && err.response.status >= 400 && err.response.status <= 500) {
        setErr(err.response.data.msg)
      }
    }
  }
  // admin - user data
  const onClick2UserDataCancel = async (e) => {
      e.preventDefault()
      setEdit2(false)
  }
  const onClick2UserEdit = async(x) => {
      await setEdit2(true)
      console.log('edit btn clicked:',x)
      // setData2({ _id:x._id, username:x.username, email:x.email, pwd:x.pwd, role:x.role })
      setData2(x)
  }
  const onClick2UserUpdate = async (e) => {
    e.preventDefault()
    try {
      const { data: res } = await axios.post( `${APIURL}/api/mongo/userdata/update`, {role:'admin',data2: data2})
      console.log('admin user update btn clicked 2', res)
      setMsg(res.msg)
      await setData({...data, username:res.data2.username, email:res.data2.email, pwd:res.data2.pwd, role:res.data2.role, usersdata:res.data3})
      setTimeout(() => {
        setMsg('')
        setEdit2(false)
      }, 1)
    } catch (err) {
      if (err.response && err.response.status >= 400 && err.response.status <= 500) {
        setErr(err.response.data.msg)
      }
    }
  }
  const onClick2UserDelete = async (e) => {
    e.preventDefault()
    console.log('remove (', data, ') account')
    try {
      const { data: res } = await axios.post(`${APIURL}/api/mongo/userdata/delete`, data)
      console.log('account deleted: ', res.msg)
      localStorage.removeItem('mongodb_token')
      setMsg(res.msg)
      setTimeout(() => {
        window.location.reload()
      }, 2500)
    } catch (err) {
      if (err.response && err.response.status >= 400 && err.response.status <= 500) {
        setErr(err.response.data.msg)
      }
    }
  }
  // admin - users data
  const onChange2Input = ({ currentTarget: input }) => { setData2({ ...data2, [input.name]: input.value }) }
  const onClick2UsersDelete = async(e, x, y) => {
    e.preventDefault()
    const { data: res } = await axios.post(`${APIURL}/api/mongo/usersdata/delete`, {id:x, username:y})
    try{
    setTimeout(() => {
    setData({...data, usersdata:res.data3})
    setMsg(res.msg)
    }, 2)
  } catch(err){
    if (err.response && err.response.status >= 400 && err.response.status <= 500) {
        setErrMsg(err.response.msg)
    }
  }
  }
  const onClick2UsersEdit = async (x) => {
      await setUpdateUserId(x._id)
      setData2(x)
  }
  const onClick2UsersUpdate = async(e) => {
      e.preventDefault()
      try{
          const {data:res} = await axios.post(`${APIURL}/api/mongo/usersdata/update`, {data1:data.id, data2:data2})
          setUpdateUserId(null)
          return setTimeout(() => {
              setData({...data,username:res.data2.username, email:res.data2.email, pwd:res.data2.pwd, role:res.data2.role,usersdata:res.data3})
          }, 1)
      } catch(err){
          if (err.response && err.response.status >= 400 && err.response.status <= 500) {
              setErrMsg(err.response.msg)
          }
      }
  }
  const onClick2UsersCancel = async (e) => {
      e.preventDefault()
      setUpdateUserId('')
      setData2({ lastname:'', firstname:'', email: '', pwd: '', role:'' })
  }
  return (
    <>
      <div className='login-container'>
      <div className='notification-container'>
          {msg && <div className='notification flex flex-row justify-between'><div>{msg}</div><div><IoIosClose onClick={onClick2CloseMsg}/></div></div>}
          {errMsg && <div className='account-err-msg flex flex-row justify-between'><div>{errMsg}</div><div><IoIosClose onClick={onClick2CloseErrMsg}/></div></div>}
      </div>
      {data.role === 'admin'? <div>
        <div className='login-nav flex-row'><div>MongoDB Main <span>Admin</span></div>
        <div>
          <button
            className='logout-btn flex'
            onClick={onClick2Logout}
          ><FiLogOut />Logout</button>
          <div className='logout-container'>
           • This Login Token is available for <br/>
           Issued date:{new Date(data.iatstring).getUTCMonth() + 1}.{new Date(data.iatstring).getUTCDate()}.{new Date(data.iatstring).getUTCFullYear()} {new Date(data.iatstring).toLocaleTimeString()}<br/>
           Expire date: {new Date(data.expstring).getUTCMonth() + 1}.{new Date(data.expstring).getUTCDate()}.{new Date(data.expstring).getUTCFullYear()} {new Date(data.expstring).toLocaleTimeString()}<br/>
          • You have {timer} left
          </div>
          </div>
        </div>
        <h4>Member Accounts</h4>
        <div className='login-admin-container'>
          <div className='ttl flex justify-items-start'>
            <div></div>
            <div>Account ID</div>
            <div>Username</div>
            <div>Email</div>
            <div>Pwd</div>
            <div>Role</div>
            <div></div>
          </div>
          <>{(data.usersdata).map((x, key) =>
            <>{updateUserId !== x._id?
            <div className='item flex flex-row justify-items-start'>
            <div>{key+1}</div>
            <div>{x._id}</div>
            <div>{x.username}</div>
            <div>{x.email}</div>
            <div>{x.pwd}</div>
            <div>{x.role}</div>
          <div className='flex btn-pc'><button onClick={()=> onClick2UsersEdit(x)} className='edit-btn'>Edit</button>
          <button onClick={(e)=> onClick2UsersDelete(e,x._id,x.username)} className='delete2-btn'>Delete</button></div>
          <div className='flex btn-sp'>
          <div className='edit2-btn' style={{maskImage: `url("data:image/svg+xml,${svgStringMdEdit}")`}}>
          <div className='edit2-btn-bg' onClick={()=> onClick2UsersEdit(x)}></div></div>
          <div className='delete3-btn' style={{maskImage: `url("data:image/svg+xml,${svgStringIoIosClose}")`}}>
          <div className='delete3-btn-bg' onClick={(e)=> onClick2UsersDelete(e,x._id,x.username)}></div></div></div>
          </div> : <div className='item-edit flex flex-row justify-items-start text-left'>
          <div>{key+1}</div>
          <div>{x._id}</div>
          <div><input className='inputA' type='text' name='username' defaultValue={x.username} onChange={onChange2Input} />
          </div>
            <div><input className='inputA' type='email' name='email' defaultValue={x.email} onChange={onChange2Input} /></div>
            <div><input className='inputA' type='text' name='pwd' defaultValue={x.pwd} onChange={onChange2Input} /></div>
            <div><select name="role" defaultValue={x.role} onChange={onChange2Input}>
            <option hidden value="default">{x.role}</option>
            <option value="admin">Admin</option>
            <option value="normal member">Normal Member</option>
            <option value="premiere member">Premiere Member</option>
            </select></div>
            {err && <div className='create-new-account-err'>{err}</div>}
            <div className='flex'>
            <button onClick={onClick2UsersCancel} type='button'className='cancel-btn'>Cancel</button>
            <button onClick={onClick2UsersUpdate} type='button' className='update2-btn'
            disabled={!(data2.email && data2.pwd && data2.username)}>Update</button></div>
            </div>
            }</>
          )}</>
          <br/><br/>
          <h4>Your Account</h4>
          <div className='login-admin-container-user'>
            <div className='ttl flex justify-items-start'>
            <div></div>
            <div>Account ID</div>
            <div>Username</div>
            <div>Email</div>
            <div>Pwd</div>
            <div>Role</div>
            <div></div>
            </div>
          {edit2 !== false ?
            <div className='item-edit flex justify-items-start text-left'>
            <div></div>
            <div className='text-left'>{data2.id}</div>
            <div><input className='inputA' type='text' name='username' defaultValue={data2.username} onChange={onChange2Input} /></div>
            <div><input className='inputA' type='email' name='email' defaultValue={data2.email} onChange={onChange2Input} /></div>
            <div><input className='inputA' type='text' name='pwd' defaultValue={data2.pwd} onChange={onChange2Input} /></div>
            <div><select name="role" defaultValue={data2.role} onChange={onChange2Input}>
            <option value="admin">Admin</option>
            <option value="normal member">Normal Member</option>
            <option value="premiere member">Premiere Member</option>
            </select></div>
            <div className='flex'><button
            className='cancel-btn' onClick={onClick2UserDataCancel}
            >Cancel</button><button
            onClick={onClick2UserUpdate}
              className='text-left update2-btn'
              type='button'
              disabled={!(data2.username&&data2.email&&data2.pwd)}
            >Update</button></div>
            {/* {err && <div className='create-new-account-err'>{err}</div>} */}
          </div> : <div className='item flex flex-row justify-items-start'><div></div><div>{data.id}</div><div>{data.username}</div><div>{data.email}</div><div>{data.pwd}</div><div>{data.role}</div><div className='flex btn-pc'><button className='edit-btn' onClick={()=> onClick2UserEdit(data)}
          >Edit</button><button className='delete2-btn text-xs' onClick={onClick2UserDelete}>Delete Account</button></div><div className='flex btn-sp'>
          <div className='edit2-btn' style={{maskImage: `url("data:image/svg+xml,${svgStringMdEdit}")`}}>
          <div className='edit2-btn-bg' onClick={()=> onClick2UserEdit(data)}></div></div>
          <div className='delete3-btn' style={{maskImage: `url("data:image/svg+xml,${svgStringIoIosClose}")`}}>
          <div className='delete3-btn-bg' onClick={onClick2UserDelete}></div></div></div></div>}
          </div>
          <br />
        </div>
        </div>:
        <>
        <div className='login-nav'>
          <div>MongoDB Main</div>
          <div>
            <button
              className='logout-btn flex'
              onClick={onClick2Logout}
            ><FiLogOut />Logout</button></div>
        </div>
        <div className='login-contents-container'
        >
          <div className='menu-item mb-4'><b>Change account details</b>
           {edit ? <button className='cancel-btn'onClick={() => setEdit(!edit)}>Cancel</button>:<button className='edit-btn'
            onClick={()=> onClick2MyAccountEdit(data)}>Edit</button>}
          </div>
          {edit ? <form onSubmit={onSubmit2MyAccountUpdate}>
            <b>Account ID:{data.id}</b>
            <div>
              <label>Username</label><br/>
              <input className='create-new-account-input' type='text' name='username' defaultValue={data2.username} onChange={onChange2Input} />
            </div>
            <div>
              <label>Email Address</label><br/>
              <input className='create-new-account-input' type='email' name='email' defaultValue={data2.email} onChange={onChange2Input} />
            </div>
            <div>
              <label>Password</label><br/>
              <input className='create-new-account-input' type='text' name='pwd' defaultValue={data2.pwd} onChange={onChange2Input} />
            </div>
            Role:{data2.role}<br/>
            <button
              className='update-btn'
              type='submit'
              disabled={!(data2.username&&data2.email&&data2.pwd)}
            >Update</button>
            {err && <div className='create-new-account-err'>{err}</div>}
          </form> : <>Account ID:{data.id}<br />Username:{data.username}<br />Email Address:{data.email}<br />Password: {data.pwd}<br />Role:{data.role}</>}
          <br />
          <div className='menu-item mt-4'>
            <b>Delete account</b>
            <button
              className='delete-btn'
              onClick={onClick2MyAccountDelete}
            >Delete Account</button>
          </div>
        </div>
        <div>
          • Login is available on {new Date(data.iatstring).getUTCMonth() + 1}.{new Date(data.iatstring).getUTCDate()}.{new Date(data.iatstring).getUTCFullYear()} {new Date(data.iatstring).toLocaleTimeString()} - {new Date(data.expstring).getUTCMonth() + 1}.{new Date(data.expstring).getUTCDate()}.{new Date(data.expstring).getUTCFullYear()} {new Date(data.expstring).toLocaleTimeString()}<br/>
          • You have {timer} left
       </div>
        </>
      }
      </div>
    </>
  )
}

export default LoginWithDifferentDB