import React from 'react'
import {
  FaLinkedinIn,
  FaTwitter
} from 'react-icons/fa'
import {
  AiOutlineRead
} from 'react-icons/ai'
import '../scss/index.css';

const externalLinkList = [
  {
    id: 1,
    icon: <AiOutlineRead />,
    url: '#',
    classname: 'icon_blog'
  },
  {
    id: 2,
    icon: <FaLinkedinIn />,
    url: '#',
    classname: 'icon_linkedin'
  },
  {
    id: 3,
    icon: <FaTwitter />,
    url: '#',
    classname: 'icon_twitter'
  }
]
const footer = () => {
  return (
    <footer>
      <div className='left-block'>
        <div className='external-link flex'>
          {externalLinkList.map(iconLink => {
            return (
              <a
                key={iconLink.id}
                href={iconLink.url}
                className={iconLink.classname}
              >
                {iconLink.icon}
              </a>
            )
          })}
        </div>
        <div className='honourCode_Phone'>
          <div class="text"><b>Following Professional Developer Rules</b><br />I respect web applications created by other developers. When I publish my products in public,  I will not use other one’s code for my products.</div>
        </div>
        <p className='copyright'>Copyright {new Date().getFullYear()} All rights reserved to Kaede Nakashima.</p>
      </div>
      <div className='honourCode_PC'>
        <div class="text"><b>Following Professional Developer Rules</b><br />I respect web applications created by other developers. When I publish my products in public, I will not use other one’s code for my products.</div>
      </div>
    </footer>
  )
}

export default footer;
